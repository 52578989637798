import React, { useState, useEffect } from 'react';
import { Box, Button, List, ListItem, ListItemText, ListItemIcon, Checkbox, Grid, Typography } from '@mui/material';

function TransferList({ users, subUserData, name, handleCheckedLeft,    handleToggle,
    checked,
    left,
    right,
    handleCheckedRight}) {
  // Filter out the subUserData.user_id from the initial list




  const customList = (title, items) => (
    <Box sx={{ width: 150, height: 230, border: '1px solid #ccc', overflow: 'auto' }}>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>{title}</Typography>
      <List dense component="div" role="list">
        {items.map((user) => {
          const labelId = `transfer-list-item-${user.user_id}-label`;

          return (
            <ListItem key={user.user_id} role="listitem" button onClick={handleToggle(user)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(user) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${user.first_name} ${user.last_name} (${user.username})`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Box>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('Active Employees', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
           
          <Button sx={{ mb: 2 }} variant="outlined" onClick={handleCheckedRight} disabled={checked.length === 0 || left.length === 0}>
            &gt;
          </Button>
          <Button sx={{ mb: 2 }} variant="outlined" onClick={handleCheckedLeft} disabled={checked.length === 0 || right.length === 0}>
            &lt;
          </Button>
   
        </Grid>
      </Grid>
      <Grid item>{customList(name, right)}</Grid>   
       </Grid>
  );
}

export default TransferList;