import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';  
import { useAuth0 } from '@auth0/auth0-react';



const CheckoutForm = ({ open, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;



  const { user, getAccessTokenSilently } = useAuth0();
  const [hasSubscription, setHasSubscription] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);


  useEffect(() => {
    const checkSubscription = async () => {
      try {
        // Get the access token if your backend API is secured
        const token = await getAccessTokenSilently();

        // Call the backend API using fetch
        const response = await fetch(`${apiUrl}/subscriptions/check-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Send the token in the request if necessary
          },
          body: JSON.stringify({ auth0_user: user.sub }),
        });

        // Check if the request was successful
        if (!response.ok) {
          throw new Error('Failed to check subscription');
        }

        // Parse the JSON response
        const data = await response.json();

        // Update the subscription state
        setHasSubscription(data.hasSubscription);
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    if (user) {
      checkSubscription();
    }
  }, [user, getAccessTokenSilently]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
        return;
    }

    setLoading(true);

    // Create payment method
    const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
    });

    if (createPaymentMethodError) {
        setError(createPaymentMethodError.message);
        setLoading(false);
        return;
    }


    // Call backend to create subscription
    const response = await fetch(`${apiUrl}/subscriptions/create-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email,
            paymentMethodId: paymentMethod.id,
        }),
    });

    const subscriptionResponse = await response.json();

    if (response.ok) {
        // Confirm the subscription with the client secret
        const { error: confirmError } = await stripe.confirmCardPayment(subscriptionResponse.clientSecret, {
            payment_method: paymentMethod.id, // Include payment method
        });

        if (confirmError) {
            setError(confirmError.message);
            console.error('Confirm Error:', confirmError); // Log confirmation error
        } else {
            alert('Subscription successful!');
        }
    } else {
        setError(subscriptionResponse.error);
        console.error('Subscription Error:', subscriptionResponse.error); // Log subscription error
    }

    setLoading(false);
};






  return (
    <Dialog open={open} onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px", backgroundColor: '#FFFFFF', width: '400px', height: '300px', padding: 2 } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle>
        <Typography>
          Manage Your Subscription
        </Typography>
      </DialogTitle>
      <DialogContent>
      <div>
      {hasSubscription === null ? (
        <p>Checking subscription status...</p>
      ) : hasSubscription ? (
        <p>You have an active subscription!</p>
      ) : (
        <p>No active subscription found.</p>
      )}
    </div>

    <div>
      {subscriptionDetails ? (
        <div>
          <h2>Subscription Details</h2>
          <p><strong>Subscription Name:</strong> {subscriptionDetails.subscriptionName}</p>
          <p><strong>Renewal Date:</strong> {new Date(subscriptionDetails.renewalDate).toLocaleDateString()}</p>
          <p><strong>Cost:</strong> ${subscriptionDetails.cost}</p>
          <p><strong>Last 4 digits of card:</strong> {subscriptionDetails.last4}</p>
          <p><strong>Payment Recurrence:</strong> {subscriptionDetails.paymentReoccurrence}</p>
        </div>
      ) : (
        <p>Loading subscription details...</p>
      )}
    </div>

      </DialogContent>
    </Dialog>
  );
};

export default CheckoutForm;