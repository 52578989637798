import React, {useState, useEffect} from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button
} from "@mui/material";
import { styled } from "@mui/system";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'src/components/payments/CheckoutForm.js';
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import InactivePlanCard from "../billingComponents/inactivePlanCard";
import ActivePlanCard from "../billingComponents/activePlanCard";
import { useAccountContext } from "src/context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useCancelSubscription from 'src/components/payments/hooks/useCancelSubscription.js';
import NewSubscription from 'src/components/payments/components/NewSubscription.js';
import { useSubscriptionDetails } from 'src/components/payments/routes/useSubscriptionDetails';
import { useValidateSubscription } from 'src/components/payments/routes/validateSubscription';








const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);



const PaymentHistoryContainer = styled("div")({
  padding: "1rem",
  paddingTop: "2rem", // Add top padding to the payment history card
});

function Billing({loading, error}) {
  const apiUrl = process.env.REACT_APP_API_URL; 
  const [open, setOpen] = useState(false);  
  const { planDetails, planError } = usePlanDetails();
  const [billingHistory, setBillingHistory] = useState([]);
  const [loadingBilling, setLoadingBilling] = useState(true);
  const [billingError, setBillingError] = useState(null);
  const {accountData} = useAccountContext();  
  const { getAccessTokenSilently } = useAuth0();
  const { isCancelling, cancelError, cancelSuccess, cancelSubscription } = useCancelSubscription();
  const { subscriptionDetails, refetch: refetchSubscriptionDetails } = useSubscriptionDetails(apiUrl); // Assuming useSubscriptionDetails has a refetch function
  const { subscriptionValid, refreshSubscription} = useValidateSubscription();  
  


  useEffect(() => {
    if (subscriptionValid) {
      refetchSubscriptionDetails();
      refreshSubscription();
    }
  }, [subscriptionValid, refetchSubscriptionDetails]);

  // Callback function to refresh subscription details after update
  const handleSubscriptionUpdate = () => {
    refetchSubscriptionDetails();  // Refresh subscription details after an update
    refreshSubscription();  // Refresh the subscription status
    
  };



  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#FFFFFF',height: '42.5px', letterSpacing:'-1%', lineHeight:'-1%' ,minHeight:'65px'   

          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({  

    color: '#447c92',borderBottom: 'none',letterSpacing:'-2%', lineHeight:'-2%',minHeight:'65px'
  });

  const StyledRow = styled(TableCell)({  
    color:'#48768C', fontWeight: 500,letterSpacing:'-2%', lineHeight:'-2%' ,
  });

  const StyledHeaderText = styled(Typography)({ 
    fontWeight:500, fontSize:13.5, align:'center',margin:'auto', letterSpacing:'-2%', lineHeight:'-2%',minHeight:'20px', color:'#48768C'
  });



  const onClose = () => {
    setOpen(false);
  }




  const customerId = accountData ? accountData.customer_id : null;  
  const subscriptionId = subscriptionDetails ? subscriptionDetails.lastSubscriptionId  : null; 
  const priceId = planDetails ? planDetails.plan_price_id : null;





  const handleCancelClick = () => {
    if (customerId && subscriptionId) {
      cancelSubscription(customerId, subscriptionId);
    }
  };


  const fetchBillingHistory = async (customerId) => {
    try {
      setLoadingBilling(true);

      // Get the access token
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`/subscriptions/plans/billing-history?customer_id=${customerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Send the token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch billing history');
      }

      const data = await response.json();
      setBillingHistory(data.billing_history);
    } catch (error) {
      setBillingError('Error fetching billing history');
      console.error('Error fetching billing history:', error);
    } finally {
      setLoadingBilling(false);
    }
  };

  // Fetch billing history when the component mounts
  useEffect(() => {
    if (customerId) {
      fetchBillingHistory(customerId);
    }
  }, [customerId]);






  return (
    <Box sx={{ width: "100%", mt: 5, }}>
      <Box
        sx={{
          width: "98%",
            overflow: 'auto',height: '74vh',


        }}
      >


        <Box
          sx={{
            fontSize: ".9rem",
            fontWeight: 600,
            letterSpacing: "0.02em",
            lineHeight: 1.5,
            color: "#48768C",
          }}
        >
{subscriptionValid && subscriptionDetails ? (
        <ActivePlanCard
        name={subscriptionDetails ? subscriptionDetails.subscriptionName : ''} 
        subscriptionValid={subscriptionValid}
        status={subscriptionDetails.subscriptionStatus ? subscriptionDetails.subscriptionStatus.charAt(0).toUpperCase() + subscriptionDetails.subscriptionStatus.slice(1) : ''}        
        cost={ subscriptionDetails ? '$' + subscriptionDetails.cost : ''}
        reoccurrence={subscriptionDetails && subscriptionDetails.paymentReoccurrence ? subscriptionDetails.paymentReoccurrence.charAt(0).toUpperCase() + subscriptionDetails.paymentReoccurrence.slice(1) + 'ly' : ''}  
        paymentMethod={
          subscriptionDetails && subscriptionDetails.last4 !== 'No card found' 
            ? '**** **** **** ' + subscriptionDetails.last4 
            : 'No Active Payment Method'
        }        
        subscriptionId={subscriptionId}
        customerId={customerId}
        onSubscriptionUpdate={handleSubscriptionUpdate} 
        planDetails={planDetails}
        stripePromise={stripePromise}
        priceId={priceId}


        />
          ) : (
            <ActivePlanCard
            name={planDetails ? planDetails.plan_name : ''}
            status={subscriptionDetails ? subscriptionDetails.subscriptionStatus.charAt(0).toUpperCase() + subscriptionDetails.subscriptionStatus.slice(1) : ''}
            cost={planDetails ? '$' + (planDetails.plan_cost / 100).toFixed(2) : ''}
            subscriptionValid={ subscriptionValid}
            subscriptionId={subscriptionId}
            customerId={customerId}
            priceId={planDetails ? planDetails.plan_price_id : ''}  
            onSubscriptionUpdate={handleSubscriptionUpdate} 
            planDetails={planDetails}
            stripePromise={stripePromise}
            


              />
          )}



        </Box>

        <Typography sx={{ mb: 1.5, mt: 3.5, color:'#48768C', fontSize:16.5, fontWeight:600,ml:.75}} gutterBottom>
          Recent Payment History
        </Typography>
        <PaymentHistoryContainer sx={{ margin: 0, padding: 0,              ml:.75,
 }}>
          <TableContainer
            component={Paper}
            sx={{
              margin: 0,
              padding: 0,
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              border: '1.25px solid rgba(173, 216, 230, 0.5)',
              borderRadius: "6px",
              
            }}
          >
           <Table size="small" sx={{ minHeight: '42vh' }}>
        <TableHead>
          <TableRow sx={{color: "#48768C",}}>
            <TableCell sx={{color: "#48768C",}}><strong>Date</strong></TableCell>
            <TableCell sx={{color: "#48768C",}}><strong>Description</strong></TableCell>
            <TableCell><strong>Amount Paid</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
            <TableCell><strong>Invoice Link</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billingHistory && billingHistory.length > 0 ? (
            billingHistory.map((invoice) => (
              <TableRow key={invoice.invoice_id}>
                <StyledRow>{invoice.date}</StyledRow>
                <StyledRow>{invoice.description}</StyledRow>
                <StyledRow>${invoice.amount_paid}</StyledRow>
                <StyledRow>{invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}</StyledRow>
                <TableCell>
                  <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
                    View Invoice
                  </a>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} sx={{ backgroundColor: 'rgba(191, 218, 231, 0.1)' }}>
                <Typography
                  align="center"
                  sx={{
                    mt: 5,
                    width: '100%',
                    padding: '20px',
                    color: '#48768C',
                    fontSize: 16,
                    ml: 2,
                    justifyContent: 'center',
                    height: '15vh',
                    borderLeft: 'none',
                    borderRight: 'none',
                    fontWeight: 600,
                  }}
                >
                  No Recent Payment History...
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
          </TableContainer>
        </PaymentHistoryContainer>
      </Box>
    
    

    </Box>
  );
}

export default Billing;
