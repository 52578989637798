import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import EditIcon from "src/public/assets/icons/icons/cancel.png";
import PreviewIcon from "src/public/assets/icons/icons/preview.png";
import PreviewDisabledIcon from "src/public/assets/icons/icons/previewDisabled.png";
import ConfirmIcon from "src/public/assets/icons/icons/confirm.png";
import ConfirmDisabledIcon from "src/public/assets/icons/icons/confirmDisabled.png";
import AddField from "./components/AddField";
import NotificationTitle from "./components/NotificationTitle";
import RequiredField from "./components/RequiredField";
import AddRequiredField from "./components/AddField";
import OptionalField from "./components/OptionalField";
import PreviewNotification from "./components/PreviewNotification";
import { useNotificationState } from "./hooks/useNotificationState";
import { useStateManager } from "./hooks/useStateManager";
import { useAccountContext } from "src/context/AccountContext";
import { useAuth } from "src/context/AuthContext";
import {renderPreviewFieldTypes} from './hooks/renderPreviewFieldTypes.js';
import useColorPicker from 'src/components/AccountPage/Sections/UserNotifications/Components/AddNewNotification/components/ColorPicker/useColorPicker.js'; // Import the custom hook




const NewNotificationType = ({ createNew, setCreateNew }) => {
  const [addRequiredField, setAddRequiredField] = React.useState(false);
  const [addOptionalField, setAddOptionalField] = React.useState(false);
  const {
    handleKeyDown,
    notificationTitle,
    requiredFieldName,
    optionalFieldName,
    handleSetTitle,
    handleSetRequiredFieldName,
    handleSetOptionalFieldName,
    setSelectedIcon,
    selectedIcon,
    handleCleaRequiredFieldName,
    requiredFieldDataSaved,
    selectedNotificationType,
    setSelectedNotificationType,
    handleSelectFieldType,
    handleSaveRequiredFieldData,
    handleSaveNewFieldData,
    handleClearRequiredFieldData,
    setOptionalIcon,
    optionalIcon,
    handleClearOptionalFieldName,
    optionalFieldDataSaved,
    optionalSelectedNotificationType,
    setOptionalSelectedNotificationType,
    handleSelectOptionalFieldType,
    handleClearOptionalFieldData,
    handleSaveOptionalFieldData,
    handleClearTitle,
    handlePrintFieldTitle,
    printedFieldTitle,
    isTitleDefault,
  } = useNotificationState();
  const [requiredFieldButtonEnabled, setRequiredFieldButtonEnabled] =
    useState(false);
  const [saveNotificationButtonEnabled, setSaveNotificationButtonEnabled] =
    React.useState(false);
  const [confirmClear, setConfirmClear] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { accountData } = useAccountContext();
  const {subUserData} = useAuth();
  const { selectedMockData, optionalMockData } = renderPreviewFieldTypes(selectedNotificationType, optionalSelectedNotificationType);
  const [isLoading, setIsLoading] = useState(false);  
  const {colors, handleIconClick} = useColorPicker(); // Use the custom hook
  const [enableClearButton, setEnableClearButton] = useState(false);  

  





  const companyName = accountData?.company_name;
    const phoneNumber = subUserData?.location_phone;
    const email = subUserData?.email;
    const website = accountData?.company_website;




  useEffect(() => {
    const enableRequiredFieldButtons =
      requiredFieldName !== "" && selectedIcon !== null;
    setRequiredFieldButtonEnabled(enableRequiredFieldButtons);
  }, [requiredFieldName, selectedIcon]);

  // Enable Save And Submit Notification To Backend API, with additional optional field logic
  useEffect(() => {
    const enableSaveNotificationButton =
      isTitleDefault !== true &&
      colors.isDefault !== true &&
      requiredFieldName !== "" &&
      selectedIcon !== null &&
      selectedNotificationType !== null &&
      // Check if optional fields are valid (optionalIcon or optionalFieldName set but optionalSelectedNotificationType is missing)
      !(
        (optionalIcon !== null || optionalFieldName !== "") &&
        optionalSelectedNotificationType === null
      );

    setSaveNotificationButtonEnabled(enableSaveNotificationButton);
  }, [
    notificationTitle,
    requiredFieldName,
    selectedIcon,
    selectedNotificationType,
    optionalIcon,
    optionalFieldName,
    optionalSelectedNotificationType,
    isTitleDefault,
    colors.isDefault,
    
  ]);


  const handleConfirmClear = () => {
    setConfirmClear(true);
  };

  const handleCancelButtonClick = () => {
    setCreateNew(false);
  };

  const handleKeepEditing = () => {
    setConfirmClear(false);
  };

  const handleOpenPreview = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleSubmitFieldData = async () => {
    try {
      setIsLoading(true);
      await handleSaveNewFieldData(colors.titleColor); // This will wait for handleSaveNewFieldData to finish
    } catch (error) {
      console.error("Error saving field data:", error);
      // Optionally handle the error, e.g. show a message to the user
    } finally {
      setIsLoading(false); // This will always run after handleSaveNewFieldData
      setCreateNew(false);
    }
  };


const handleCancelOptionalField = () => {
  handleClearOptionalFieldData();
  setAddOptionalField(false);
};


  



  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack
        direction="row"
        sx={{ display: "flex", flexDirection: "row", width: "100%" }}
      >
        <Typography
          noWrap
          sx={{
            color:  isTitleDefault ?  `#48768C` : colors.titleColor,
            borderRadius: "8px",
            fontSize: 16,
            fontWeight: 600,
            textAlign: "left",
            position: "relative", // Required for absolute positioning of animation
            letterSpacing: "-2%",
            lineheight: "-2%",
            pt: 2,
            ml: 2,
          }}
        >
          {printedFieldTitle}
        </Typography>

        {confirmClear ? (
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 1,
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: `#48768C`,
                borderRadius: "8px",
                fontSize: 15.5,
                fontWeight: 600,
                textAlign: "left",
                position: "relative", // Required for absolute positioning of animation
                letterSpacing: "-2%",
                lineHeight: "1.5", // Correct line height for better vertical alignment
                ml: 2,
                ml: "auto", // Pushes the text to the left with auto margin
                display: "flex",
                alignItems: "center", // Aligns text vertically
                mr: 1.25,
              }}
            >
              Quit Without Saving?
            </Typography>
            <IconButton
              onClick={handleCancelButtonClick}
              sx={{ display: "flex", mr: 1 }}
            >
              <img
                src={ConfirmIcon}
                style={{
                  border: "1px solid #B0BEC5",
                  width: "auto",
                  height: "24px",
                  backgroundColor: "transparent",
                  borderRadius: "6px",
                  padding: "1px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleKeepEditing}
              sx={{ display: "flex", mr: 1 }}
            >
              <img
                src={EditIcon}
                style={{
                  border: "1px solid #B0BEC5",
                  width: "auto",
                  height: "24px",
                  backgroundColor: "transparent",
                  borderRadius: "6px",
                  padding: "1px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            </IconButton>
          </Stack>
        ) : (
          <IconButton
            onClick={handleConfirmClear}
            sx={{ display: "flex", mr: 1, mt: 0.5, ml: "auto" }}
          >
            <img
              src={EditIcon}
              style={{
                border: "1px solid #B0BEC5",
                width: "auto",
                height: "24px",
                backgroundColor: "transparent",
                borderRadius: "6px",
                padding: "1px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </IconButton>
        )}
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "auto",
          ml: 2,
        }}
      >
        <NotificationTitle
          notificationTitle={notificationTitle}
          handleSetTitle={handleSetTitle}
          handleKeyDown={handleKeyDown}
          handleIconClick={handleIconClick}
          colors={colors}
          handleClearTitle={handleClearTitle}
          handlePrintFieldTitle={handlePrintFieldTitle}
          isTitleDefault={isTitleDefault}
        />
        <AddField
          fieldNamePlaceholder="Required Field Name"
          handleKeyDown={handleKeyDown}
          fieldName={requiredFieldName}
          handleSetFieldName={handleSetRequiredFieldName}
          handleSelectedIcon={setSelectedIcon}
          selectedIcon={selectedIcon}
          handleClearFieldName={handleCleaRequiredFieldName}
          handleSaveFieldData={handleSaveRequiredFieldData}
          fieldDataSaved={requiredFieldDataSaved}
          selectedNotificationType={selectedNotificationType}
          handleSelectFieldType={handleSelectFieldType}
          setSelectedNotificationType={setSelectedNotificationType}
          handleClearFieldData={handleClearRequiredFieldData}
          colors={colors}
          enableClearButton={enableClearButton}
        />
        {!addOptionalField ? (
          <OptionalField
            addOptionalField={addOptionalField}
            setAddOptionalField={setAddOptionalField}
          />
        ) : (
          <AddField
            fieldNamePlaceholder="Optional Field Name"
            handleKeyDown={handleKeyDown}
            fieldName={optionalFieldName}
            handleSetFieldName={handleSetOptionalFieldName}
            handleSelectedIcon={setOptionalIcon}
            selectedIcon={optionalIcon}
            setSelectedIcon={setOptionalIcon}
            handleClearFieldName={handleCancelOptionalField}
            handleSaveFieldData={handleSaveOptionalFieldData}
            fieldDataSaved={optionalFieldDataSaved}
            handleSelectFieldType={handleSelectOptionalFieldType}
            setSelectedNotificationType={setOptionalSelectedNotificationType}
            handleClearFieldData={handleClearOptionalFieldData}
            selectedNotificationType={optionalSelectedNotificationType}
            colors={colors}
            enableClearButton={!enableClearButton}

          />
        )}
      </Box>
      <Stack
        direction="row"
        sx={{ display: "flex", flexDirection: "row", width: "100%", mt: 4 }}
      >
        <IconButton
          disabled={!saveNotificationButtonEnabled || isLoading}
          className="previewNotificationButton"
          onClick={handleOpenPreview}
          sx={{ display: "flex", marginLeft: "auto", mr: 3, mt: 0.5, mb: 2 }}
        >
          {!saveNotificationButtonEnabled ? (
            <img
              src={PreviewDisabledIcon}
              alt="detailsIcon"
              style={{
                width: "auto",
                height: "25px",
                backgroundColor: "transparent",
                borderRadius: "6px",
                padding: "1px",
                marginRight: "5px",
              }}
            />
          ) : (
            <img
              src={PreviewIcon}
              alt="detailsIcon"
              style={{
                width: "auto",
                height: "25px",
                backgroundColor: "transparent",
                borderRadius: "6px",
                padding: "1px",
                marginRight: "5px",
              }}
            />
          )}
          Preview Notification
        </IconButton>

        <IconButton
          disabled={!saveNotificationButtonEnabled || isLoading}
          className="saveNotificationButton"
          onClick={handleSubmitFieldData}
          sx={{ display: "flex", mr: 1, mt: 0.5, mb: 2 }}
        >
          {!saveNotificationButtonEnabled ? (
            <img
              src={ConfirmDisabledIcon}
              alt="detailsIcon"
              style={{
                width: "auto",
                height: "25px",
                backgroundColor: "transparent",
                borderRadius: "6px",
                padding: "1px",
                marginRight: "5px",
              }}
            />
          ) : (
            <img
              src={ConfirmIcon}
              alt="detailsIcon"
              style={{
                width: "auto",
                height: "25px",
                backgroundColor: "transparent",
                borderRadius: "6px",
                padding: "1px",
                marginRight: "5px",
              }}
            />
          )}
         {isLoading ?  'Adding New Notification' : 'Save Notification'}
        </IconButton>
      </Stack>
      <PreviewNotification
        open={open}
        onClose={handleClose}
        companyName={companyName}
        phoneNumber={phoneNumber}
        ticketNumber={"123122"}
        requiredFieldValues={""}
        additionalMessage={""}
        title={notificationTitle}
        messagePrefix={""}
        contextMessage={requiredFieldName}
        optionalMessagePrefix={optionalFieldName}
        optionalFieldValues={''}
        currentDayOfWeek={""}
        currentHours={""}
        formattedLocationPhone={""}
        locationEmail={email}
        companyWebsite={website}
        userCurrentDate={""}
        defaultMessage={""}
        twoWayEnabled={""}
        optionalMockData={optionalMockData}
        selectedMockData={selectedMockData}
      />
    </Box>
  );
};

export default NewNotificationType;
