export const dismissItem = async (item,  getAccessTokenSilently, userId) => {
    try {

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = await getAccessTokenSilently();


        // Set the appropriate query parameters based on the item
        const params = new URLSearchParams({
            auth0_user: userId, // Ensure you have this user id in onboardingData
        });

        // Set the corresponding dismiss parameter based on the item
        if (item === 'addUser') {
            params.append('dismissUser', 'true');
        } else if (item === 'addLocation') {
            params.append('dismissLocation', 'true');
        } else if (item === 'addLogo') {
            params.append('dismissLogo', 'true');
        } else if (item === 'confirmHours') {
            params.append('dismissHours', 'true');
            
        }

        const response = await fetch(`${apiUrl}/dismiss_onboarding?${params.toString()}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

     
        const data = await response.json();
    } catch (error) {
        console.error('Error dismissing item:', error);
    }
};