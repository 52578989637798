export const renderPreviewFieldTypes = (selectedNotificationType, optionalSelectedNotificationType) => {
    const getMockData = (type) => {
        switch (type) {
            case 'CUR':
                return '$24.99';
            case 'DATE':
                return '03/17/2025';
            case 'TIME':
                return '12:35 PM';
            case 'DATE/TIME':
                return '03/17/2025 12:35 PM';
            case 'TEXT':
                return 'Sample Text';
            case 'TMR':
                return '2 hours '; // or 'False' based on the boolean
            default:
                return ''; // Return empty string for unknown types
        }
    };

    return {
        selectedMockData: getMockData(selectedNotificationType),
        optionalMockData: getMockData(optionalSelectedNotificationType),
    };
};