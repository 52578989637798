import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, Box, Icon, Fade } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import WelcomeImage from "src/public/assets/WelcomeImage.png";
import { useAuth } from "src/context/AuthContext";

const WelcomeDialog = ({ isOpen, onNext }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { setSubUserData } = useAuth();

  useEffect(() => {
    // Clear all items from local storage when component mounts
    localStorage.clear();
  }, []);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = WelcomeImage;
    preloadImage.onload = () => {
      setImageLoaded(true);
    };

    // Optional: Handle error case
    preloadImage.onerror = () => {
      console.error("Failed to preload image");
    };

    return () => {
      // Cleanup function to prevent memory leaks
      preloadImage.onload = null;
      preloadImage.onerror = null;
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      slotProps={{
        backdrop: {
          outline: "none", // Remove the default focus outline

          onClick: null, // Disable closing the modal by clicking on the backdrop
        },
      }}
      disableEscapeKeyDown // Disable closing the modal by pressing the escape key
    >
      <Fade in={isOpen} timeout={300}>
        <Box
          sx={{
            outline: "none", // Remove the default focus outline
            alignContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
            borderRadius: "40px",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#FFFFFF",
            color: "#48768C",
          }}
        >
          <Typography
            sx={{
              mt: 3,
              fontSize: 22,
              textAlign: "center",
              mb: 1,
              fontWeight: 600,
            }}
          >
            Welcome to NanoNotify!
          </Typography>

          <img
            src={WelcomeImage}
            alt="Welcome"
            style={{
              display: "block",
              margin: "auto",
              marginBottom: "20px",
              maxWidth: "50%",
              maxHeight: "50%",
            }}
          />

          <Typography
            sx={{
              textAlign: "center",
              mb: 3,
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Let's finish setting up your new account
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                whiteSpace: "pre-wrap",

                textAlign: "center",
                mb: 4,
                fontSize: 15,
                width: "80%",
              }}
            >
              We just need to get a few details about your company and how you
              plan to send notifications to your customers
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                backgroundColor: "rgba(72, 118, 140, 0.8)", // Adjusted color with transparency
                padding: 1,
                borderRadius: "15px",
                mb: 3,
                width: "85%",
                fontSize: 17,
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "rgba(72, 118, 140, 0.7)", // Adjusted hover color with transparency
                },
                "&:active": {
                  backgroundColor: "rgba(72, 118, 140, 0.5)", // Adjusted active color with transparency
                },
              }}
              onClick={onNext}
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default WelcomeDialog;
