// sendVerificationEmail.js

import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useSendVerificationEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const sendVerificationEmail = async (userId) => {
    try {
      const token = await getAccessTokenSilently();  // Await the token

      const response = await fetch('auth/send-verification-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,   // Ensure token is awaited
        },
        body: JSON.stringify({ userId }),
      });

      if (response.ok) {
        const data = await response.json();
        return { success: true, message: data.message };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.error || 'Error sending verification email.' };
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      return { success: false, message: 'Error sending verification email.' };
    }
  };

  const handleSubmit = async (e, userId) => {
    e.preventDefault();
    if (!userId) {
      setMessage('Please enter a user ID.');
      return;
    }

    setIsLoading(true);
    setMessage('');

    const result = await sendVerificationEmail(userId);

    setMessage(result.message);
    setIsLoading(false);
  };

  return { isLoading, message, handleSubmit };
};