import React, { useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  IconButton,
  Box,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import bellIcon from "../../../../../../../../src/public/assets/icons/messageModal/bell.png";
import { useAccountContext } from "../../../../../../../context/AccountContext"; // Import useAccountContext from AccountContext
import { useUserSelectedNotifications } from 'src/context/UserNotificationsContext.js'; 





const PreviewDialogTypography = ({ children, color }) => (



  
  <Typography
    color="textSecondary"
    sx={{
      color: "#48768C",
      fontSize: "15px",
      fontWeight: 600,
    }}
  >
    {children}
  </Typography>
);

function PreviewNotification({
  open,
  onClose,
  companyName,
  phoneNumber,
  ticketNumber,
  requiredFieldValues,
  title,
  contextMessage,
  optionalMessagePrefix,
  locationEmail,
  companyWebsite,
  optionalMockData,
  selectedMockData
}) {
  const { value } = requiredFieldValues;
  const { selectedNotifications, businessHours } = useUserSelectedNotifications();





  const currentDate = new Date();
  const currentDayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
  const businessHoursForCurrentDay = businessHours[currentDayOfWeek];
  const month = currentDate.getMonth() + 1; // January is 0, so add 1 to get the correct month
  const day = currentDate.getDate();
  // Format the date as "m/d"
  const userCurrentDate = `${month}/${day}`;


  let currentHours = '';
if (businessHoursForCurrentDay) {
  const { start_time, end_time, } = businessHoursForCurrentDay;
  currentHours =  `${start_time} - ${end_time}`;
} else {
  currentHours = 'Not available';
}




  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "19px",
          border: "1px solid rgba(173, 216, 230, 0.5)",
        },
      }}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 5,
            color: "#576369",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "8px",
            width: "32px",
            height: "32px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>

        <Box
          sx={{
            position: "relative",
            backgroundColor: "#FFFFFF",
            color: "#576369",
            padding: "20px",
            borderRadius: "15px",

            width: "100%",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pl: 0.5,
              pr: 0.5,
              pb: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                color: "#48768C",
                fontWeight: 600,
                justifyContent: "left",
              }}
            >
              Preview Notification{" "}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                color: "#48768C",
                mb: 1,
                fontWeight: 600,
                justifyContent: "left",
                mb: 1,
              }}
            >
            Your new notification will appear like the example below when it’s sent to your customer.
            </Typography>
          </Box>

          <Paper elevation={5} sx={{ borderRadius: "15px" }}>
            <Box
              sx={{
                position: "relative",

                color: "#576369",
                padding: "10px",
                borderRadius: "15px",
                border: "1px solid #B0BEC5",
                width: "100%",
                backgroundColor: "rgba(191, 218, 231, 0.08)",
                border: "1px solid rgba(173, 216, 230, 0.7)",
              }}
            >
              <PreviewDialogTypography>
                {" "}
                {companyName} Status Update
              </PreviewDialogTypography>
              <PreviewDialogTypography>{`Ticket Number: ${ticketNumber}`}</PreviewDialogTypography>
              <PreviewDialogTypography>
                {`Status: ${title}`}{" "}
              </PreviewDialogTypography>

                <Box gap={.5} sx={{ mt: 1, mb: 1, display:'flex', flexDirection:'row' }}>
                  <PreviewDialogTypography
                    sx={{ color: "#576369", fontWeight: 600,  }}
                  >
                    {contextMessage}:{" "}
                  </PreviewDialogTypography>

                  <PreviewDialogTypography >

{selectedMockData}
</PreviewDialogTypography>
                </Box>
                <Box >



                  {optionalMessagePrefix && (
                <Box gap={.5} sx={{ mt: 1, mb: 1, display:'flex', flexDirection:'row' }}>

                        <PreviewDialogTypography
                        sx={{ mt: 1, mb: 1, color: "#576369", fontWeight: 600 }}
                      >
                        {optionalMessagePrefix}:{" "}
                      </PreviewDialogTypography>
                  

                      <PreviewDialogTypography >

                      {optionalMockData} 
                  </PreviewDialogTypography>
                  </Box>
                  )}
  
         

              <Box sx={{ mt: 1, mb: 1 }}>
                <PreviewDialogTypography
                  sx={{ color: "#576369", fontWeight: 600 }}
                >
                  This number is for automated alerts only and cannot receive
                  replies. If you have any questions or need assistance, please
                  call or email us directly.
                </PreviewDialogTypography>
              </Box>
              <PreviewDialogTypography>
                Contact Us: {phoneNumber}
              </PreviewDialogTypography>
              <PreviewDialogTypography>
                {locationEmail} | {companyWebsite}
              </PreviewDialogTypography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <PreviewDialogTypography
                  sx={{ color: "#576369", fontWeight: 600 }}
                >
                  Business Hours for {currentDayOfWeek} {userCurrentDate}:&nbsp;
                  {currentHours}
                </PreviewDialogTypography>
              </Box>
              </Box>  
            </Box>
          </Paper>
        </Box>
      </Box>

    </Dialog>
  );
}

export default PreviewNotification;
