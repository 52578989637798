import React, {useState, useEffect} from 'react';
import { Button, Box, Paper,IconButton, Typography, TextField, Stack } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import TitleIcon from 'src/public/assets/icons/icons/newTitle.png';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useNotificationState } from '../hooks/useNotificationState';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ColorPicker from './ColorPicker/ColorPicker';  
import  useColorPicker  from './ColorPicker/useColorPicker'; // Import the custom hook
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import LightTooltip from '@mui/material/Tooltip'; // Make sure you have this imported


import './styles.css';

const NotificationTitle = ({ notificationTitle, handleSetTitle, handleKeyDown, handleIconClick, colors, handleClearTitle, handlePrintFieldTitle,isTitleDefault }) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null);
  const [titleButtonsEnabled, setTitleButtonsEnabled] = useState(false);



  const handleBoxClick = (event) => {
    setColorPickerAnchorEl(event.currentTarget);
    setColorPickerOpen(true);
  };

  const handleColorPopoverClose = () => {
    setColorPickerOpen(false);
    setColorPickerAnchorEl(null);
  };



  useEffect(() => {
    const enableTitleButtons =
    notificationTitle !== '' &&
      colors.isDefault !== true;    
      setTitleButtonsEnabled(enableTitleButtons);
  }, [notificationTitle, colors.isDefault]);







  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': '#E0E3E7',
              '--TextField-brandBorderHoverColor': '#B2BAC2',
              '--TextField-brandBorderFocusedColor': '#6F7E8C',
              '& label.Mui-focused': {
                color: '#48768C',
                borderBottom: 'none',
  
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: 'var(--TextField-brandBorderColor)',
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: 'none',
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: 'none',
                color:'#48768C'
  
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              '&::before, &::after': {
                borderBottom: 'none',
                color:'#48768C'
  
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: 'none',
              },
              '&.Mui-focused:after': {
                borderBottom: 'none',
                color:'#48768C'
  
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              '&::before': {
                borderBottom: 'none',
                color:'#48768C'
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
              },
              '&.Mui-focused:after': {
                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                color:'#48768C'
  
              },
            },
          },
        },
      },
    });
  
    const outerTheme = useTheme();


   
    return (
         <Box sx={{display:'flex', flexDirection:'column', width:'100%',
            mt:1, 
         }} >
                <Box sx={{ marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    paddingTop: '20px',
    paddingBottom: '20px',
    minWidth: '30%',
    maxWidth: '30%',
    borderRadius: '8px',
    height: '35px',
    backgroundColor: isTitleDefault ? '#F2F9FF' : '#FFFFFF',
    border: `1px solid ${colors.borderColor}`,  // Corrected this line
    }} >
                  <Box style={{
                        width: "auto",
                        alignItems: 'center',  // Vertically center the icon
               
                        display: 'flex',

                        borderRadius: "3px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: 'pointer',
                        transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
                        color:'#72C5B4',
                        '&:hover': {
                          backgroundColor: '#F5FAFE',
                          cursor: 'pointer',
                          color: '#526071',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                    onClick={handleBoxClick}

                >
                <SquareRoundedIcon
                    style={{

                        color: colors.titleColor , 
                        width: "auto",
                        height: "19px",
                        borderRadius: "4px",
                        border: "1px solid #72C5B4",
                        padding:'.5px',

                        
                    
                    }}
                />
                </Box>
                      <ThemeProvider theme={customTheme}>
        <ColorPicker
          colorPickerOpen={colorPickerOpen}
          colorPickerAnchorEl={colorPickerAnchorEl}
          handleColorPopoverClose={handleColorPopoverClose}
          handleIconClick={handleIconClick} // Pass the color change handler
        />

        <TextField
          hiddenLabel
          placeholder="Notification Name"
          variant="filled"
          size="small"
          name="fieldName"
          value={notificationTitle || ''} 
          onChange={(e) => {
            const value = e.target.value;
            handleSetTitle(value.charAt(0).toUpperCase() + value.slice(1));
          }}
          onKeyDown={handleKeyDown}
          sx={{
            alignContent: 'center',
            ml: 1,
            width: 411,
            mr: 0.5,
            mt: '3px',
            input: {
              color: '#48768C',
              fontWeight: 600,
              fontSize: '13.5px',
              padding: 0,
              alignItems: 'center',
              backgroundColor: isTitleDefault ?  '#F2F9FF' : '#FFFFFF',
              fontFamily: 'Source Sans Pro, sans-serif',
              
            },
          }}
        />
          <Stack sx={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', justifyContent: 'flex-end' }}>
          <LightTooltip title="Select a color and enter a notification name to continue" disableHoverListener={titleButtonsEnabled}>
            <span>
              {isTitleDefault && (
              <IconButton
                disabled={!titleButtonsEnabled}
                onClick={handlePrintFieldTitle}
                className="field_save_button"
              >
                <CheckCircleTwoToneIcon
                  sx={{
                    ml: 0.5,
                    fontSize: '21px',
                    border: '1px solid #747C86',
                    borderRadius: '5px',
                    color: !titleButtonsEnabled ? '#BEBEBE' : '#48B09B',
                  }}
                />
              </IconButton>
              )}
            </span>
          </LightTooltip>
          <IconButton
          disabled={notificationTitle === '' && colors.isDefault === true}
          
           className="icon_cancel_button" onClick={handleClearTitle}>
            <HighlightOffTwoToneIcon sx={{ fontSize: '21px', border: '1px solid #747C86', borderRadius: '5px' }} />
          </IconButton>
        </Stack>
        </ThemeProvider>

               
                </Box>  
              
            </Box>

    );
};

export default NotificationTitle;