import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	Card,
	Typography,
	Paper,
	TableFooter,
	Button,
	Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia
} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import {useAppContext} from '../../context/AppContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {useChart} from '../../components/chart';
import LinearProgress from '@mui/material/LinearProgress';
import {useAccountContext} from '../../context/AccountContext'; // Import AppProvider from AccountContext
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import  fetchGlobalNotifications  from '../../utils/notificationUtils';  
import { styled } from '@mui/system';
import noMessage from "../../public/assets/illustrations/historyEmpty.png";
import ApproveIcon from "../../public/assets/icons/icons/approve.png";
import DeclineIcon from "../../public/assets/icons/icons/decline.png";
import { set } from 'lodash';
import PendIcon from "../../public/assets/icons/icons/pend.png";
import PendLiveIcon from "../../public/assets/icons/icons/pendLive.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageDetails from '../../../src/components/modal/MessageDetails.js';
import DetailsIcon from "../../public/assets/icons/icons/details.png";
import { useWidgetContext } from 'src/context/WidgetContext';
import PendingApproval from "../../public/assets/illustrations/pendingApproval.png";
import StatusTag from 'src/components/color-utils/statusTags';
import CreateCustomerModal from 'src/components/modal/CreateCustomerModal';
import saveCustomerData from '../../components/customers/saveCustomerData';
import { useAuth } from 'src/context/AuthContext';














const StatusIcon = ({ pendingApproval }) => {

  let icon;
  let color;

  // Check if pendingApproval is null or contains a phone numbe
  if (pendingApproval === 'PENDING_APPROVAL') {
    // Render PendIcon when pendingApproval is null
    icon = 
    <img
    src={PendIcon}
    alt="bell"
    style={{
      display: 'flex', // Add flex display
      justifyContent: 'center', // Center content horizontally
      width: "auto",
      height: "32px",
      backgroundColor: "transparent",
      padding: "1px",
      marginTop: '2px',

    }}
  />
    color = '#A1848F'; // Custom color for pending approval
  } else {
    // Render PendLiveIcon when pendingApproval is not null (contains a phone number)
    icon = 
    <img
    src={PendLiveIcon}
    alt="bell"
    style={{
      display: 'flex', // Add flex display
      justifyContent: 'center', // Center content horizontally
      width: "auto",
      height: "32px",
      backgroundColor: "transparent",
      padding: "1px",
      marginTop: '2px',
    }}
  />
    color = '#A1848F'; // Custom color for pending approval
  }

  return icon ? <span style={{ color,  }}>{icon}</span> : null;
};


export default function CustomerFeed() {
	const apiUrl = process.env.REACT_APP_API_URL;
	const {reloadComponents} = useAppContext();
	const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
	const {accountData} = useAccountContext(); // Use the accountData from the context

	const [loading, setLoading] = useState(false);
	const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const anchorRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const {handleReloadComponents} = useAppContext();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const { pendingApprovalData, loadingPendingApproval, fetchAllWidgetTypes, pauseAutoRefresh, resumeAutoRefresh } = useWidgetContext();



  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);

  

	useEffect(() => {
		// Access userData and subUserData wherever you need in your component
	}, [accountData]);





  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [customerFeedData, setCustomerFeedData] = useState([]);
  const { subUserData } = useAuth();  



    // Handle saving customer
    const handleSaveCustomer = async (firstName, lastName, location_name) => {
      const success = await saveCustomerData(selectedPhoneNumber, firstName, lastName, user.sub, location_name,  );
    
      if (success) {
        handleCloseModal();
      }
    };  
    
    
    
      const handleOpenModal = () => {
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };
      
    





  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState('auto');

  useLayoutEffect(() => {
    const calculateInitialHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        setContainerHeight(containerHeight - 125);
      }
    };

    calculateInitialHeight(); // Calculate initial height before component mounts

    return () => {
      // Cleanup function (if needed)
    };
  }, []); // Run only once on component mount

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        setContainerHeight(containerHeight - 125);
      }
    };

    const viewportWidth = window.innerWidth;
    if (viewportWidth >= 1280 && viewportWidth <= 1600) {
      const timeoutId = setTimeout(handleResize, 1000); // Delay to ensure proper rendering
      window.addEventListener('resize', handleResize);

      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      // Reset the container height if the viewport width is not within the specified range
      setContainerHeight('auto');
    }
  }, []);

	const handleOpenPopover = (event, rowData) => {
		setAnchorEl(event.currentTarget); // Set anchor element for the clicked row
    pauseAutoRefresh(); // Pause auto refresh when Popover is open  
		setSelectedRow(rowData);
		setPopoverOpen(true);
	};

	const handleClosePopover = () => {
		setPopoverOpen(false);
    setSelectedRow(null);
    resumeAutoRefresh();  
	};

	const handleAction = async (action) => {
    try {
        const accessToken = await getAccessTokenSilently(); // Get access token
        const response = await fetch(`${apiUrl}/api/communications/${action}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Include access token in headers
            },
            body: JSON.stringify({
                notificationId: selectedRow.ticket_number,
                sub: user.sub,
            }),
        });
        if (response.ok) {
            // Handle success
            handleSnackbarOpen(); // Open Snackbar after successful update
            fetchAllWidgetTypes(); // Fetch all widget types after successful update
            handleReloadComponents(); // Reload components after successful update

        } else {
            // Handle error
            console.error(
                `Failed to ${action} notification: ${response.statusText}`,
            );
        }
    } catch (error) {
        console.error(`Failed to ${action} notification:`, error);
    } finally {
        setPopoverOpen(false);
        resumeAutoRefresh();  
    }
};


	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		const options = {month: 'short', day: 'numeric', year: 'numeric'};
		return date.toLocaleDateString(undefined, options);
	};

	const formatTime = (timeStr) => {
		const time = new Date(timeStr);
		const options = {hour: 'numeric', minute: 'numeric', hour12: true};
		return time.toLocaleTimeString(undefined, options);
	};

	const formatPhoneNumber = (phoneNumber) => {
		const cleaned = ('' + phoneNumber).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
	};




  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setVisibleRows(screenWidth <= 1440 ? customerFeedData.slice(0, 8) : customerFeedData);
    };

    handleResize(); // Call initially

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [customerFeedData]);







  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#FFFFFF',height: '42.5px', letterSpacing:'-1%', lineHeight:'-1%' ,minHeight:'65px'   

          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({  

    color: '#447c92',borderBottom: 'none',letterSpacing:'-2%', lineHeight:'-2%',minHeight:'65px'
  });

  const StyledRow = styled(TableCell)({  
    color:'#5e9fbc', fontWeight: 600,letterSpacing:'-2%', lineHeight:'-2%' 
  });

  const StyledHeaderText = styled(Typography)({ 
    fontWeight:500, fontSize:13.5, align:'center',margin:'auto', letterSpacing:'-2%', lineHeight:'-2%',minHeight:'20px'
  });




	const handleSnackbarOpen = () => {
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
    setSelectedRow(null);

	};


  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);  
    setIsMessageDetailsOpen(true);
  };


  const handleOpenDialog = (row) => {
    setConfirmationOpen(true);
    setSelectedRow(row);
  };  
  
  
  const handleCloseMessageDetails = () => {
    setIsMessageDetailsOpen(false);
    setSelectedRow(null);
    resumeAutoRefresh();  

  };



  const HeaderTypography = styled(Typography)({
    backgroundColor: '#48768C',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: 13.5,
  });

  const RowTypography = styled(Typography)({
    color: '#48768C',
    fontSize: 15,
    height: '32px', // Adjust the height as needed
    display: 'flex',
    alignItems: 'center', // Center the content vertically
    justifyContent: 'center', // Center the content horizontally
  });

  const rowHeight = containerHeight === 'auto' ? 'auto' : containerHeight / customerFeedData.length;


  return (
    <div>
      <Box
        sx={{
          mt: .5,
          borderRadius: '15px',
          backgroundColor: '#FFFFFF',
        }}
      >

  
<Paper elevation={4}
      sx={{
        justifyContent: 'center',alignSelf:'center',
        width: '99.5%',
        borderRadius: '15px',
        backgroundColor: 'transparent',
        borderBottom: '1.25px solid rgba(173, 216, 230, 0.8)',


      }}
    >

{(loadingPendingApproval || !pendingApprovalData) && (

            <Box>
          <Typography
            align="center"
            sx={{
              background: '#e2f2f7',
                            width: '100%',
              padding: '20px',
              color: '#447c92',
              fontSize: '.8rem',
              fontWeight: 600,  
            }}
          >
                Loading Your Pending Approval Notifications...
              </Typography>
              <LinearProgress
                color="secondary"
                sx={{
                  height: 2,
                  '& .MuiLinearProgress-barColorSecondary': {
                    backgroundColor: '#2BBB9E',
                  },
                }}
              />
            </Box>
          )}
  
  {!loadingPendingApproval && pendingApprovalData && pendingApprovalData.length === 0 && (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      background: 'radial-gradient(circle, #EEF6F9, #D4E9F2)',
      alignItems: 'center',
      height: '69vh',
      borderRadius: '10px',
      padding: '20px', // Add padding for better spacing
      overflow: 'hidden', // Ensures no content overflows out of the Box

    }}
  >
    <Box sx={{ textAlign: 'center', mt: -1, mb: -1 }}>
      <CardMedia
        component="img"
        src={PendingApproval}
        alt="bell"
        sx={{
          width: { xs: '70vw', sm: '50vw', md: '35vw', lg: '30vw' }, // Responsive width
          height: 'auto', // Maintain aspect ratio
          maxHeight: '100%', // Ensures the image doesn't exceed the container height
          maxWidth: '100%', // Ensures the image doesn't exceed the container width
          objectFit: 'contain', // Keeps the image within the box, maintaining aspect ratio
        }}
      />
    </Box>
    <Typography
      align="center"
      sx={{
        mt: -9,
        fontSize: { xs: 16, sm: 18 }, // Responsive font size
        color: '#447c92',
        mb: '15px',
        textAlign: 'center',
        fontWeight: 600,
      }}
    >
      No Notifications Pending Approval
    </Typography>

    <Typography
      align="center"
      sx={{
        fontSize: { xs: 14, sm: 16 }, // Responsive font size
        color: '#447c92',
        mb: '15px',
        textAlign: 'center',
        fontWeight: 600,
      }}
    >
      Notifications that are pending approval from your customers will automatically be displayed here.
    </Typography>
  </Box>
)}
 

  {!loadingPendingApproval && pendingApprovalData && pendingApprovalData.length > 0 && (
      <TableContainer
      sx={{  background:'#f1f8fb',  borderRadius:'12px',         border: '1.25px solid rgba(173, 216, 230, 0.5)',display:'flex',
   
      
      }}
    >
    <Table size="small">
    <ThemeProvider theme={theme}>

    <TableHead >

        <TableRow >
          <StyledHeader align="center" style={{ width: '14%',  }}> <StyledHeaderText> Date Sent  </StyledHeaderText></StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Time Sent</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Sent To</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText>Ticket Number</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '20%' }}><StyledHeaderText>Notification</StyledHeaderText> </StyledHeader>
          {accountData && accountData.single_location === false && (
            <StyledHeader align="center" style={{ width: '8%' }}><StyledHeaderText> Location</StyledHeaderText> </StyledHeader>
          )}
          <StyledHeader align="center" style={{ width: '10%' }}><StyledHeaderText> Status</StyledHeaderText> </StyledHeader>
        </TableRow>
      </TableHead>
      </ThemeProvider>

      <TableBody
      >
        {pendingApprovalData.map((activity, index) => (
            <TableRow
              key={index}
              onClick={(event) => {
                handleOpenPopover(event, activity);
                setSelectedRow(activity); // Set the selected row when TableRow is clicked
              }}
                sx={{ height: '53px', '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: selectedRow === activity ? 'rgba(173, 216, 230, 0.3)' : 'transparent',

                '&:hover': {
                  backgroundColor: 'rgba(173, 216, 230, 0.1)', // Background color on hover
                  boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)', // Box shadow on hover
                  cursor: 'pointer', // Change cursor to pointer on hover
                },
              }}
              >

            <StyledRow align="center" sx={{}}  >{formatDate(activity.created_at)}</StyledRow>
            <StyledRow align="center">{formatTime(activity.created_at)}</StyledRow>
            <StyledRow align="center">{activity.customer_name ? activity.customer_name : formatPhoneNumber(activity.phone_number)}</StyledRow>
            <StyledRow align="center">{activity.ticket_number}</StyledRow>
            <StyledRow align="center">
              <Box height="32px" display="flex" alignItems="center" justifyContent="center" width="100%">
                <StatusTag status={activity.status} />
              </Box>
            </StyledRow>
            {accountData && accountData.single_location === false && (
              <StyledRow align="center">{activity.location}</StyledRow>
            )}
<StyledRow
  align="center"
  sx={{
    display: 'flex', // Add flex display
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center',
    alignContent: 'center',
  }}
>
<StatusIcon pendingApproval={activity.pending_approval} />
</StyledRow>

          </TableRow>
        ))}
      </TableBody>
    </Table>
    <MessageDetails 
    open={isMessageDetailsOpen} 
    selectedRow={selectedRow} onClose={handleCloseMessageDetails}
    handleOpenModal={handleOpenModal}
    setSelectedPhoneNumber={setSelectedPhoneNumber} 
    />




<CreateCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSaveCustomer={handleSaveCustomer}
          selectedPhoneNumber={selectedPhoneNumber}
          location_name={subUserData?.location_name || ''} // Check if subUserData exists before accessing location_name
          />
  </TableContainer>
      )}
        </Paper>
      </Box>  
      <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl} // Use the anchor element state variable
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom', // Render Popover below the anchor element
                        horizontal: 'center', // Center horizontally
                      }}
                      transformOrigin={{
                        vertical: 'top', // Position Popover to the top of the anchor element
                        horizontal: 'center', // Center horizontally
                      }}
                    
                      PaperProps={{
                        sx: {
                          border: '1.25px solid rgba(173, 216, 230, 0.6)',
                
                          borderRadius: '8px', // Adjust the value to control the roundness
                        
                                                },
                                            }}>
                                                <Box
                                                sx={{backgroundColor: '#F2F9FF',}}
                                                >


                
 
                            <Paper
                                                                            sx={{backgroundColor: '#F2F9FF'}}

                            >
     <Box sx={{ backgroundColor: '#F2F9FF' }}>
     {selectedRow && selectedRow.pending_approval !== null ? (
  <div>
    <Box
                onClick={() => { handleOpenMessageDetails(selectedRow); setPopoverOpen(false); }}  // Open message details on row click
              sx={{
    pt:'5px',  
    px:1,
    flexDirection: 'row', 
    display: 'flex', 
    backgroundColor: '#F2F9FF',
    color: '#8A5D6E', 
    '&:hover': {
      backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
    },
    '&.Mui-selected': {
      backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
    },
    alignItems: 'center', // Center vertically
    cursor: 'pointer', // Change cursor to pointer on hover
      }}
>
<img
    src={DetailsIcon}
    alt="bell"
    style={{
      width: 'auto',
      height: '30px',
      borderRadius: '10px',
      padding: '3px',
      borderRadius: '6px',
    }}
  />
  <Typography 
    sx={{
      display: 'flex', 
      color:'#5e9fbc', 
      fontSize: 14, 
      fontWeight: 600, 
      textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
    }}
  >
    View Notification Details
  </Typography>
</Box>
    {/* Approve button */}
    <Box
      onClick={(event, selectedRow) => {
        event.stopPropagation(); // Stop event propagation
        setPopoverOpen(false);
        setConfirmationOpen(true);
        setActionType('approve');
      }} 
      sx={{
        px:1,

        paddingBottom: 1,
        mb: -1,
        flexDirection: 'row', 
        display: 'flex', 
        backgroundColor: '#F2F9FF',
        color: '#8A5D6E', 
        '&:hover': {
          backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
        },
        '&.Mui-selected': {
          backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
        },
        alignItems: 'center', // Center vertically
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
    >
      <img
        src={ApproveIcon}
        alt="bell"
        style={{
          width: 'auto',
          height: '30px',
          borderRadius: '10px',
          padding: '3px',
          borderRadius: '6px',
        }}
      />
      <Typography 
        sx={{
          display: 'flex', 
          color:'#5e9fbc', 
          fontSize: 14, 
          fontWeight: 600, 
          textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
        }}
      >
        Customer Approved
      </Typography>
    </Box>
    {/* Deny button */}
    <Box
      onClick={(event) => {
        event.stopPropagation(); // Stop event propagation
        setPopoverOpen(false);
        setConfirmationOpen(true);
        setActionType('deny');

      }} 
      sx={{
        px: 1,
        pb: '5px',
        flexDirection: 'row', 
        display: 'flex', 
        backgroundColor: '#F2F9FF',
        color: '#8A5D6E', 
        '&:hover': {
          backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
        },
        '&.Mui-selected': {
          backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
        },
        alignItems: 'center', // Center vertically
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
    >
      <img
        src={DeclineIcon}
        alt="bell"
        style={{
          width: 'auto',
          height: '30px',
          borderRadius: '10px',
          padding: '3px',
          borderRadius: '6px',
        }}
      />
      <Typography 
        sx={{
          display: 'flex', 
          color:'#5e9fbc', 
          fontSize: 14, 
          fontWeight: 600, 
          textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
        }}
      >
        Customer Declined
      </Typography>
    </Box>
  </div>
) : (
  <div>
    <Box
                onClick={() => { handleOpenMessageDetails(selectedRow); setPopoverOpen(false); }}  // Open message details on row click
              sx={{
                pt:'5px',  
                px:1,
    flexDirection: 'row', 
    display: 'flex', 
    backgroundColor: '#F2F9FF',
    color: '#8A5D6E', 
    '&:hover': {
      backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
    },
    '&.Mui-selected': {
      backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
    },
    alignItems: 'center', // Center vertically
    cursor: 'pointer', // Change cursor to pointer on hover
      }}
>
<img
    src={DetailsIcon}
    alt="bell"
    style={{
      width: 'auto',
      height: '30px',
      borderRadius: '10px',
      padding: '3px',
      borderRadius: '6px',
    }}
  />
  <Typography 
    sx={{
      display: 'flex', 
      color:'#5e9fbc', 
      fontWeight: 600,
      fontSize: 14, 
      textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
    }}
  >
    View Notification Details
  </Typography>
</Box>
    {/* Approve button */}
    <Box
      onClick={(event) => {
        event.stopPropagation(); // Stop event propagation
        setActionType('approve');
        handleAction('approve');
      }} 
      sx={{
        px:1,

        paddingBottom: 1,
        mb: -1,
        flexDirection: 'row', 
        display: 'flex', 
        backgroundColor: '#F2F9FF',
        color: '#8A5D6E', 
        '&:hover': {
          backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
        },
        '&.Mui-selected': {
          backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
        },
        alignItems: 'center', // Center vertically
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
    >
      <img
        src={ApproveIcon}
        alt="bell"
        style={{
          width: 'auto',
          height: '30px',
          borderRadius: '10px',
          padding: '3px',
          borderRadius: '6px',
        }}
      />
      <Typography 
        sx={{
          display: 'flex', 
          color:'#5e9fbc', 
          fontSize: 14, 
          fontWeight: 600, 
          textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
        }}
      >
        Customer Approved
      </Typography>
    </Box>
    {/* Deny button */}
    <Box
      onClick={(event) => {
        event.stopPropagation(); // Stop event propagation
        setActionType('deny');
        handleAction('deny'); 
      }} 
      sx={{
        px: 1,
        pb: '5px',
        flexDirection: 'row', 
        display: 'flex', 
        backgroundColor: '#F2F9FF',
        color: '#8A5D6E', 
        '&:hover': {
          backgroundColor: '#E6F1FF', // Adjusted hover color to a lighter shade
        },
        '&.Mui-selected': {
          backgroundColor: '#E6F1FF', // Adjusted selected color to a darker shade
        },
        alignItems: 'center', // Center vertically
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
    >
      <img
        src={DeclineIcon}
        alt="bell"
        style={{
          width: 'auto',
          height: '30px',
          borderRadius: '10px',
          padding: '3px',
          borderRadius: '6px',
        }}
      />
      <Typography 
        sx={{
          display: 'flex', 
          color:'#5e9fbc', 
          fontSize: 14, 
          fontWeight: 600, 
          textAlign: 'center', // Fix typo here: 'textAlign' instead of 'alignText'
        }}
      >
        Customer Declined
      </Typography>
    </Box>
  </div>
)}

  </Box>
  </Paper>
  </Box>
</Popover>
            <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={1500}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <MuiAlert
                        onClose={handleSnackbarClose}
                        severity="success"
                        sx={{ width: '100%' }}>
                        Notification updated successfully!
                    </MuiAlert>
                </Snackbar>
                <Dialog
  open={confirmationOpen && selectedRow && selectedRow.pending_approval !== null}
  onClose={() => {
    setConfirmationOpen(false);
    setSelectedRow(null);
  }}
  PaperProps={{ sx: { borderRadius: '12px', width: '800px', backgroundColor: "#F2F9FF", padding:.5 } }}
>
  <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: "#F2F9FF" }}>
    <Typography  sx={{ color: '#48768C', fontWeight: 600, lineHeight: '-2%', letterSpacing: '-2%', marginBottom:1, fontSize:'16px' }}>
      Are you sure you want to manually update this notification to {actionType === 'approve' ? 'approved' : 'declined'}?
    </Typography>
    {selectedRow && selectedRow.pending_approval && /^\d{10}$/.test(selectedRow.pending_approval) && (
    <Typography  sx={{ color: '#48768C', fontWeight: 600, lineHeight: '-2%', letterSpacing: '-2%',fontSize:'13px' }}>
      Your customer will no longer be able to update this notification by responding to it.
    </Typography>
  )}
  </DialogTitle>
  <DialogActions>
    <Box sx={{ width: '80%', display: 'flex', justifyContent: 'right', mb: 1 }}>
      <Button
        onClick={() => {
          setSelectedRow(null);
          setConfirmationOpen(false);
          setPopoverOpen(false);
          resumeAutoRefresh();  

        }}
        sx={{ color: '#48768C', borderRadius: '8px', border: '1px solid #B0BEC5', fontSize: '.8rem', ml: 2, padding:0, lineHeight: '-2%', letterSpacing: '-2%'}}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleAction(actionType);
          setConfirmationOpen(false);
        }}
        color="primary"
        variant="contained"
        disabled={loading}
        sx={{
          mr: 2,
          ml: 3,
          fontSize: '.8rem',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease',
          width: '15%',
          backgroundColor: "#48768C",
          color: 'rgba(233, 242, 255, 0.99)',
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          padding:.75, lineHeight: '-1%', letterSpacing: '-2%',
          "&:hover": {
            backgroundColor: "#3B6B7D",
          },
          "&:active": {
            backgroundColor: "#2E7D32",
          },
        }}
      >
        {loading ? "Deleting..." : "Confirm"}
      </Button>
    </Box>
  </DialogActions>
</Dialog>
       

    
  </div>
);

}


