import React, {useEffect} from 'react';  
import { Card, Grid, Typography, Button } from '@mui/material';
import NewSubscription from 'src/components/payments/components/NewSubscription.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useCancelSubscription from 'src/components/payments/hooks/useCancelSubscription.js';
import { useSubscriptionDetails } from 'src/components/payments/routes/useSubscriptionDetails';
import { useValidateSubscription } from 'src/components/payments/routes/validateSubscription';


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);




const ActivePlanCard = ({ planDetails, name, status, cost, reoccurrence, paymentMethod, subscriptionId, customerId, priceId, onSubscriptionUpdate, subscriptionValid, stripePromise} ) => {
  const apiUrl = process.env.REACT_APP_API_URL; 
    const [showNewSubscription, setShowNewSubscription] = React.useState(false);
    const { subscriptionDetails, refetch: refetchSubscriptionDetails } = useSubscriptionDetails(apiUrl); // Assuming useSubscriptionDetails has a refetch function





    const handleShowNewSubscription = () => {
        setShowNewSubscription(true);
    }


    const handleNewSubscriptionClose = () => {  
        setShowNewSubscription(false);
    }

    

    const { isCancelling, cancelError, cancelSuccess, cancelSubscription } = useCancelSubscription();



    const handleCancelClick = () => {
      if (customerId && subscriptionId) {
        cancelSubscription(customerId, subscriptionId, onSubscriptionUpdate);
      }
    };
  

      // Optional: useEffect to re-fetch data when subscriptionValid changes
  useEffect(() => {
    if (subscriptionValid) {
      refetchSubscriptionDetails();
    }
  }, [subscriptionValid, refetchSubscriptionDetails]);


  
console.log (subscriptionDetails);  

    return (

<Card
sx={{            
width:'60%', padding:1.5, backgroundColor: 'rgba(191, 218, 231, 0.1)',
border: '1px solid rgba(173, 216, 230, 0.7)', boxShadow: "0 4px 8px rgba(0,0,0,0.1)",}}
>
<Typography sx={{ mb: 1, mt: 0.5,color:'#576369', fontSize:16, fontWeight:600, color: "#48768C", }} gutterBottom>
Your Current Plan and Billing Information
</Typography>

<Grid container spacing={1}>
  <Grid item xs={5}>
  <Typography
    color="textSecondary"   
      sx={{
        mb: 1,
        mt: 1,
        ml:1,
        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      Your Current Plan
    </Typography>
    <Typography
    color="textSecondary"
      sx={{
        mb: 1,
        mt: 1,
        ml:1,
        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      Subscription Status
    </Typography>

    <Typography
                  color="textSecondary"

      sx={{
        mb: 1,
        mt: 1,
        ml:1,
        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      Your Total Cost
    </Typography>
    {subscriptionValid && (

    <Typography
    color="textSecondary"
      sx={{
        mb: 1,
        mt: 1,
        ml:1,

        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      Payment Reoccurrence
    </Typography>
    )}

{subscriptionValid ? (
  <>
    <Typography
      color="textSecondary"
      sx={{
        mb: 1,
        mt: 1,
        ml:1,

        whiteSpace: "nowrap",
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      Payment Method
    </Typography>
    
    <Button onClick={handleCancelClick} disabled={isCancelling}
     sx={{
      display: 'flex',
      border: '1px solid #48768C',
      borderRadius: '10px',
      padding: '8px',
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      ml:.25,
      mt: 2,
      mb:1,
      justifyContent: 'center',
      alignItems: 'center', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#DBEAF0',
      },
      '&:active': {
        backgroundColor: '#DBEAF0',
      },
    }}
    color="primary"
  >
      {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
    </Button>


  </>
) : (
  <Button
    onClick={handleShowNewSubscription}
    sx={{
      display: 'flex',
      border: '1px solid #48768C',
      borderRadius: '10px',
      padding: '8px',
      color: '#48768C',
      backgroundColor: '#FFFFFF',
      ml:.25,
      mt: 2,
      mb:1,
      justifyContent: 'center',
      alignItems: 'center', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#DBEAF0',
      },
      '&:active': {
        backgroundColor: '#DBEAF0',
      },
    }}
    color="primary"
  >
    Activate Your Subscription
  </Button>
)}

  </Grid>

  <Grid sx={{ }} item xs={5}>
  <Typography
      align="left"
      sx={{
        color:'#48768C', 
        mb: 1,
        mt: 1,
        ml:16,
        whiteSpace: "nowrap",
        fontWeight: 500,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      { name || 'Loading...'}
    </Typography>
    <Typography
      align="left"
      sx={{
        mb: 1,
        mt: 1,
        ml:16,
        whiteSpace: "nowrap",
        color:'#48768C', 

        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
     {status || 'Loading...'}
    </Typography>
    <Typography
      align="left"
      sx={{
        mb: 1,
        color:'#48768C', 

        mt: 1,
        ml:16,
        whiteSpace: "nowrap",
        fontWeight: 500,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      {cost || 'Loading...'}
    </Typography>
    
   {subscriptionValid && (
    <Typography
      align="left"
      sx={{
        color:'#48768C', 
        mb: 1,
        mt: 1,
        ml:16,
        whiteSpace: "nowrap",
        fontWeight: 500,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
      {reoccurrence}
    </Typography>
    )}
   
   {subscriptionValid && (

    <Typography
      align="left"
      sx={{
        mb: 1,
        mt: 1,
        ml:16,
        whiteSpace: "nowrap",
        color:'#48768C', 

        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: 1.5,
        fontSize: ".9rem",
        textTransform: "none",
      }}
    >
   {paymentMethod}  
    </Typography>
    )}




  </Grid>
</Grid>
<Elements stripe={stripePromise}>
<NewSubscription open={showNewSubscription} onClose={handleNewSubscriptionClose}
customerId={customerId} 
priceId={priceId}
onSubscriptionUpdate={onSubscriptionUpdate} 
planDetails={planDetails}
stripePromise={stripePromise}


/>
</Elements>
</Card>

    );

};

export default ActivePlanCard; 