import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppContext } from 'src/context/AppContext';
import useAddLocation from 'src/components/payments/hooks/useAddLocation';
import useAddNewUser from 'src/components/payments/hooks/useAddNewUser.js'
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import { Button } from '@mui/material';

// Load the Stripe object outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ clientSecret, customerId, priceId, apiUrl, onSubscriptionUpdate, refreshSubscription, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const {   addNewLocation } = useAddLocation();
  const { addNewUser } = useAddNewUser();
  const { planDetails } = usePlanDetails(); 



 





const handleSubmit = async (event) => {
  event.preventDefault();

  if (!stripe || !elements || !clientSecret) {
    setError('Stripe has not loaded or clientSecret is missing');
    return;
  }

  setLoading(true);

  // Confirm the SetupIntent (for subscriptions)
  const { error: setupError } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      // You can specify a return_url here if needed
    },
    redirect: 'if_required',
  });

  // Check for any error during the confirmation of SetupIntent
  if (setupError) {
    setError(setupError.message);
    setLoading(false);
    return;
  }

  // After confirming the SetupIntent, proceed with subscription creation
  try {
    const response = await fetch(`${apiUrl}/subscriptions/create-subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        customerId,
        planDetails,
      }),
    });

    const subscriptionResponse = await response.json();


    if (response.ok) {

      // Immediately charge the customer for the first invoice
      if (subscriptionResponse.latest_invoice) {
        const paymentIntentClientSecret = subscriptionResponse.latest_invoice.payment_intent;

        const { error: paymentError } = await stripe.confirmPayment({
          clientSecret: paymentIntentClientSecret,
          elements,
          confirmParams: {
            return_url: `http://${apiUrl}/dashboard/Account/Billing`, // Your return URL
          },
        });

        if (paymentError) {
          setError(paymentError.message);
          setLoading(false);
          return;
        }
      }

      onSubscriptionUpdate();
      refreshSubscription();
      onClose();
      showSnackBar('Subscription created and charged successfully', 'success');
    } else {
      // Set a more descriptive error message based on the subscriptionResponse
      setError(subscriptionResponse.error || 'Failed to create subscription');
    }
  } catch (error) {
    console.error('Error during subscription creation:', error); // Log the error for debugging
    setError('An unexpected error occurred. Please try again.'); // Generic error message
  } finally {
    setLoading(false);
  }
};

  return (
    <form onSubmit={handleSubmit}>
      {clientSecret && <PaymentElement />}
      <Button type="submit" disabled={!stripe || !clientSecret || loading}
        sx={{
          display: 'flex',
          border: '1px solid #48768C',
          borderRadius: '10px',
          padding: '8px',
          color: '#48768C',
          backgroundColor: '#FFFFFF',
          ml:.25,
          mt: 2,
          justifyContent: 'center',
          alignItems: 'center', 
          ml:'auto',
          mr:'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          width: '98%',
          '&:hover': {
            backgroundColor: '#DBEAF0',
          },
          '&:active': {
            backgroundColor: '#DBEAF0',
          },
        }}
        color="primary"
      >
                    {loading ? 'Processing...' : 'Subscribe'}

      </Button>
     
    </form>
  );
};

// Wrapper component to ensure Elements is loaded correctly
const PaymentFormWrapper = ({ customerId, priceId, apiUrl, onSubscriptionUpdate, onClose, clientSecret, refreshSubscription }) => {

    const appearance = {
      theme: 'stripe', // You can also try 'night', 'flat', 'none', etc.
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#7D4E4F',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
      },
      rules: {
        '.Label': {
          color: '#6c90a2',
        },
        '.Input': {
          backgroundColor: '#f5f8fa',
          border: '1px solid #e1e6ef',
          padding: '12px',
          fontSize: '16px',
          color: '#4E617D',
        },
        '.Input--invalid': {
          color: '#7D4E4F',
        },
        '.PaymentElement': {
          backgroundColor: '#f9fbfd',
          padding: '12px',
          borderRadius: '4px',
          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15)',
        },
      },
  };



  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';


  return (
    <div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance, loader }}>
          <PaymentForm
            clientSecret={clientSecret}
            customerId={customerId}
            priceId={priceId}
            apiUrl={apiUrl}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onClose={onClose}
            refreshSubscription={refreshSubscription}
            

          />
        </Elements>
      ) : (
        <div>Loading Payment Information...</div>
      )}
    </div>
  );
};

export default PaymentFormWrapper;