
import React from 'react';
import { Box, Paper, Typography, Divider, Stack } from '@mui/material';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';
import LabelIcon from '@mui/icons-material/Label';


const addUserCard = ({ name, title, firstName, lastName, userName,  multiUser, role }) => {



  return (
    <Box sx={{ border: '1px solid rgba(191, 218, 231, 0.5)', borderRadius: '16px', width: '100%', height: '24%', backgroundColor: '#FFFFFF',  mt: 1, padding: 1.5 }}>
      <Box sx={{ border: '1.5px solid #BCE4F6', borderRadius: '6px', width: '83%', backgroundColor: 'transparent', mb: 2 }}>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '2px',
            backgroundColor: 'transparent',
            color: '#48768C',
            py: '2px',
          }}
        >
          <AddTaskOutlinedIcon
            sx={{
              padding: '2px',
              color: '#7EC08A',
              fontSize: '24px',
              backgroundColor: 'transparent'
            }}
          />
          <Divider orientation="vertical" flexItem sx={{ borderColor: '#B0B8BC', margin: '0 8px' }} />
          <Typography
            sx={{
              fontSize: '13.5px',
              fontWeight: 600,
              color: '#4A6B75',
              letterSpacing: '1%',
              textAlign: 'center',
              lineHeight: '-2%',
            }}
          >
           {title}
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ mt: 1, height: 'auto', display: 'flex', mr: 3, mb: .25 }}>
        <Person2RoundedIcon
          sx={{
            color: '#6E8890',
            fontSize: '48px',
            border: '1.5px solid #D5DEE2',
            borderRadius: '6px',
            backgroundColor: 'rgba(191, 218, 231, 0.25)',
            padding: '3px',
          }}
        />
        <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Stack direction="row" sx={{ alignItems: 'center', mt:'2px  ' }}>

          <Typography
            sx={{
              fontSize: '14.5px',
              fontWeight: 600,
              color: '#4A6B75',
              letterSpacing: '1%',
              textAlign: 'left',
              mb: -0.35,
              mt: -0.8,
              mr: .5,
            }}
          >
            {firstName   || ''} {/* Provide a fallback if first_name is undefined */}
            </Typography>
            <Typography
            sx={{
              fontSize: '14.5px',
              fontWeight: 600,
              color: '#4A6B75',
              letterSpacing: '1%',
              textAlign: 'left',
              mb: -0.35,
              mt: -0.8
            }}
          >
            {lastName   || ''} {/* Provide a fallback if first_name is undefined */}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mb: -0.5, alignItems: 'center' }}>
            <Person2TwoToneIcon sx={{ fontSize: '16px', color: '#708B92', mr: 0.35, ml:-.2 }} />
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 600,
                color: '#868E90',
                letterSpacing: '-0.2',
                textAlign: 'left',
              }}
            >
            {userName  || ''} {/* Provide a fallback if first_name is undefined */}
            </Typography>

          </Stack>
         {multiUser && (
          <Stack direction="row" sx={{ mb: -0.19, alignItems: 'center' }}>
          <StorefrontTwoToneIcon sx={{ fontSize: '16px', color: '#708B92', mr: 0.35, ml:-.2 }} />
          <Typography
              sx={{
                fontSize: '13.5px',
                fontWeight: 600,
                color: '#868E90',
                letterSpacing: '-2%',
                textAlign: 'left',
              }}
            >
            {name  || ''} {/* Provide a fallback if first_name is undefined */}
            </Typography>
          </Stack>
          )}
           {!multiUser && (
          <Stack direction="row" sx={{ mb: -0.19, alignItems: 'center' }}>
          <LabelIcon sx={{ fontSize: '16px', color: '#7E9AA1', mr: 0.35, ml:-.2 }} />
          <Typography
              sx={{
                fontSize: '13.5px',
                fontWeight: 600,
                color: '#868E90',
                letterSpacing: '-2%',
                textAlign: 'left',
              }}
            >
            {role  || ''} {/* Provide a fallback if first_name is undefined */}
            </Typography>
          </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default addUserCard;