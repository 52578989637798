import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogTitle, Box, Stack, Button } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';  
import {useSubscriptionDetails} from '../routes/useSubscriptionDetails';
import { useValidateSubscription } from '../routes/validateSubscription';
import { useAppContext } from 'src/context/AppContext';
import useAddLocation from 'src/components/payments/hooks/useAddLocation';
import useAddNewUser from 'src/components/payments/hooks/useAddNewUser.js'
import { loadStripe } from '@stripe/stripe-js';
import PlanCard from './planCard';
import PaymentForm from './PaymentForm';  



const NewSubscription = ({ open, onClose, planDetails, customerId, onSubscriptionUpdate}) => {
  const stripe = useStripe();
  const elements = useElements();
const apiUrl = process.env.REACT_APP_API_URL;
const [clientSecret, setClientSecret] = useState(null); 
const [error, setError] = useState(null);

const priceId = planDetails ? planDetails.plan_price_id : null;
const { refreshSubscription } = useValidateSubscription();  



useEffect(() => {
 if (!customerId || !priceId) {
    return;
  }

  fetch(`${apiUrl}/subscriptions/handle-subscription`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customerId, priceId }),
  })
    .then((res) => {
      if (!res.ok) throw new Error('Failed to fetch client secret');
      return res.json();
    })
    .then((data) => {
      if (data.clientSecret) {
        setClientSecret(data.clientSecret); // Updates the state
      } else {
        throw new Error('Invalid client secret received');
      }
    })
    .catch((error) => {
      console.error('Error fetching clientSecret:', error);
      setError('Failed to initialize payment. Please try again.');
    });
}, [apiUrl, customerId, priceId]);

useEffect(() => {
  if (clientSecret) {
  }
}, [clientSecret]);






  return (
    <Dialog open={open} onClose={onClose}
    PaperProps={{ sx: { borderRadius: "20px", minWidth:'510px' } }}>
 <DialogTitle sx={{}}>
                 <Box display="flex" justifyContent="left" alignItems="left">
                 <Typography align="left" sx={{fontSize:'20px', fontWeight:550, color:'#48768C', mt:1 }}>
                   Activate Your Subscription
                   </Typography>
                 </Box>
               </DialogTitle>
               <DialogContent sx={{marginBottom:1}}>
     

<Box align="center" sx={{mt:1, mb:2}} >
<PlanCard
planDetails={planDetails}

/>


</Box>

<PaymentForm
customerId={customerId}
priceId={priceId}
clientSecret={clientSecret}
apiUrl={apiUrl}
onSubscriptionUpdate={onSubscriptionUpdate}
onClose={onClose}
refreshSubscription={refreshSubscription}
planDetails={planDetails}
/>


               </DialogContent>
   

 
    </Dialog>
  );
};

export default NewSubscription;