import React from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';
import { useAccountContext } from 'src/context/AccountContext';
import { set } from 'lodash';


const ConfirmedNotificationButton = () => {
    const {showSnackBar} = useAppContext(); 
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const { fetchAccountData, fetchOnboardingRecord, accountData } = useAccountContext();  
    const [loading, setLoading] = React.useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

 
    const handleConfirmNotification = async () => {
    
        if (!isAuthenticated || !user) {
            throw new Error('User is not authenticated');
        }
    
        try {
            setLoading(true);
            const token = await getAccessTokenSilently();
    
            const response = await fetch(`${apiUrl}/confirm_notifications`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ auth0_user: user.sub }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to fetch onboarding data: ${errorData.error || 'Unknown error'}`);
            }
    
            const result = await response.json();
            fetchOnboardingRecord();
            showSnackBar('Notification setup complete!', true);
            setLoading(false);

            // Or use a state variable to display a message
        } catch (error) {
            showSnackBar('Unable to confirm notifications. Please try again.', true);
            setLoading(false);
        }
    };

    return (
        <Button className="confirmNotificationButton"
        onClick={handleConfirmNotification} 
        > {loading ?    'Saving...' : 'Save And Confirm' }</Button>

    );
};

export default ConfirmedNotificationButton;