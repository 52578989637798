import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountContext } from 'src/context/AccountContext';

const useUpgradeToMultiLocation = () => {
    const { accountData } = useAccountContext();    
    const { getAccessTokenSilently } = useAuth0();
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [price, setPrice] = useState(null); 
    const [productId, setProductId] = useState(null);
    const [newProductId, setNewProductId] = useState(null);
    const [newPrice, setNewPrice] = useState(null);
    const [newSubscriptionData, setNewSubscriptionData] = useState(null);   
    const customerId = accountData?.customer_id; 




    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const subscriptionType = accountData.single_user ? 'singleUserMonthly' : 'multiUserMonthly';
                const response = await fetch(`${apiUrl}/subscriptions/plans/getSubscription/${subscriptionType}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const subscription = accountData.single_user ? data.singleUserMonthly : data.multiUserMonthly;
                
                setSubscriptionData(subscription);
                setPrice(subscription.price);
                setProductId(subscription.PID);
     
            } catch (err) {
                setError('Error fetching subscription data: ' + err.message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
  
        fetchSubscriptionData();

    }, []); // Dependency array includes accountData to trigger when it updates



    useEffect(() => {
        const fetchNewSubscriptionData = async () => {
            try {
                const response = await fetch(`${apiUrl}/subscriptions/plans/getSubscription/multiLocationMonthly`); // Adjust according to the title
                
                // Check if the response is OK (status code 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
  
                const data = await response.json();
                setNewSubscriptionData(data.multiLocationMonthly); // Adjust according to the title
                setNewPrice(data.multiLocationMonthly.price); // Adjust according to the title
                setNewProductId(data.multiLocationMonthly.PID); // Adjust according to the title

            } catch (err) {
                setError('Error fetching subscription data: ' + err.message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
  
        fetchNewSubscriptionData();
    }, []); // Empty dependen
  



    const upgradeToMultiLocation = async () => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${apiUrl}/subscriptions/manage/changeSubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    customerId,
                    productId, // Ensure you're sending productId here
               
                    newProductId, // Ensure you're sending newProductId here
                    newPrice,


                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update subscription');
            }

            const data = await response.json();
            setSuccess(true);
            return data; // Return the response data if needed
        } catch (err) {
            setError(err.message);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        upgradeToMultiLocation,
        success,
    };
};

export default useUpgradeToMultiLocation;