import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  LinearProgress, 
  Table, 
  TableBody,
  TableCell,  
  TableContainer, 
  TableHead,  
  TableRow,  
  TableFooter,
  CardMedia
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuth0 } from '@auth0/auth0-react';
import { useAccountContext } from '../../context/AccountContext';
import { convertToUserTimezone } from '../../utils/timezoneConverter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { styled } from '@mui/system';
import noMessage from "../../public/assets/illustrations/widgetArt.png";
import MessageDetails from '../../../src/components/modal/MessageDetails.js';
import { useAppContext } from '../../context/AppContext';
import { useWidgetContext } from 'src/context/WidgetContext';
import StatusTag from 'src/components/color-utils/statusTags';
import CreateCustomerModal from 'src/components/modal/CreateCustomerModal';
import saveCustomerData from '../../components/customers/saveCustomerData';
import { useAuth } from 'src/context/AuthContext';






const StatusIcon = ({ status }) => {
  let icon;
  let color;

  switch (status) {
    case null:
      icon = <HelpOutlineIcon />;
      color = '#7589A2'; // Blue color
      break;
    case 'Sent':
      icon = <CheckCircleIcon />;
      color = '#2BBB9E'; // Light green color
      break;
      case 'queued':
        icon = <CheckCircleIcon />;
        color = '#2BBB9E'; // Light green color
        break;
    case 'Failed':
      icon = <CancelPresentationIcon />;
      color = '#8C4350'; // Red color
      break;
    case 'Scheduled':
      icon = <AlarmOnIcon />;
      color = '#5B5B62'; // Red color
      break;
    default:
      icon = null;
  }

  return icon ? <span style={{ color, marginRight: '8px' }}>{icon}</span> : null;
};

export default function CustomerFeed() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, isAuthenticated, getAccessTokenSilently  } = useAuth0();  
  const { accountData } = useAccountContext();
  const [customerFeedData, setCustomerFeedData] = useState([]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);
  const {reloadComponents} = useAppContext();
  const { recentActivityData, loadingRecentActivity } = useWidgetContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const { subUserData } = useAuth();  










// Handle saving customer
const handleSaveCustomer = async (firstName, lastName, location_name) => {
  const success = await saveCustomerData(selectedPhoneNumber, firstName, lastName, user.sub, location_name,  );

  if (success) {
    handleCloseModal();
  }
};  



  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  


  
  
const handleOpenMessageDetails = (row) => {
  setSelectedRow(row);  
  setIsMessageDetailsOpen(true);
};


const handleCloseMessageDetails = () => {
  setIsMessageDetailsOpen(false);
};

  

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };



  



  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

  
      let visibleRowCount = 0;
  
      if (recentActivityData) {
        if (screenWidth <= 1440) {
          visibleRowCount = 9;
        } else if (screenWidth <= 1599) {
          visibleRowCount = 11; // Maximum of 5 rows for screens between 1400 and 1599
        } else {
          visibleRowCount = recentActivityData.length;
        }
      
        setVisibleRows(recentActivityData.slice(0, visibleRowCount));
      }
    };
  
    handleResize(); // Call initially
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [recentActivityData]);
  



    



  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#FFFFFF',height: '42.5px', letterSpacing:'-1%', lineHeight:'-1%' ,minHeight:'65px'   

          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({  

    color: '#447c92',borderBottom: 'none',letterSpacing:'-2%', lineHeight:'-2%',minHeight:'65px'
  });

  const StyledRow = styled(TableCell)({  
    color:'#5e9fbc', fontWeight: 600,letterSpacing:'-2%', lineHeight:'-2%' 
  });

  const StyledHeaderText = styled(Typography)({ 
    fontWeight:500, fontSize:13.5, align:'center',margin:'auto', letterSpacing:'-2%', lineHeight:'-2%',minHeight:'20px'
  });


  return (
    <Paper elevation={4}
      sx={{
        justifyContent: 'center',alignSelf:'center',
        width: '99.5%',
        borderRadius: '15px',
        backgroundColor: 'transparent',
        borderBottom: '1.25px solid rgba(173, 216, 230, 0.8)',


      }}
    >




         {(loadingRecentActivity || !recentActivityData) && (
          <Box>
            <Typography
              align="center"
              sx={{
                background: '#e2f2f7',
                width: '100%',
                padding: '20px',
                color: '#447c92',
                fontSize: '.8rem',
                fontWeight: 600,  
              }}
            >
              Loading Your Recent Activity...
            </Typography>
            <LinearProgress
              color="secondary"
              sx={{
                height: 2,
                '& .MuiLinearProgress-barColorSecondary': {
                  backgroundColor: '#2BBB9E',
                },
              }}
            />
          </Box>
        )}



{!loadingRecentActivity && recentActivityData && recentActivityData.length > 0 && (
   
   <TableContainer
    sx={{  background:'#f1f8fb',  borderRadius:'12px',         border: '1.25px solid rgba(173, 216, 230, 0.5)',display:'flex',overflowY:'hidden',

    
    }}
  >
    <Table size="small"  sx={{}}>
    <ThemeProvider theme={theme}>

    <TableHead    >

        <TableRow sx={{minHeight:'65px', }} >
          <StyledHeader align="center" style={{ width: '12%',  }}> <StyledHeaderText> Date Sent  </StyledHeaderText></StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Time Sent</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}> <StyledHeaderText>Sent To</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText>Ticket Number</StyledHeaderText> </StyledHeader>
          <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText>Notification</StyledHeaderText> </StyledHeader>
          {accountData && accountData.single_location === false && (
            <StyledHeader align="center" style={{ width: '14%' }}><StyledHeaderText> Location</StyledHeaderText> </StyledHeader>
          )}
          <StyledHeader align="center" style={{ width: '10%' }}><StyledHeaderText> Status</StyledHeaderText> </StyledHeader>
        </TableRow>
      </TableHead>
      </ThemeProvider>

      <TableBody
      >
    {visibleRows.map((activity, index) => (
            <TableRow
              key={index}
              onClick={() => handleOpenMessageDetails(activity)} // Open message details on row click
              cursor="select"  
              sx={{ height: '53px', '&:last-child td, &:last-child th': { border: 0 },
              '&:hover': {
                backgroundColor: 'rgba(173, 216, 230, 0.1)', // Background color on hover
                boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)', // Box shadow on hover
                cursor: 'pointer', // Change cursor to pointer on hover
              },
             }}
            >

            <StyledRow align="center" sx={{}}  >{formatDate(activity.created_at)}</StyledRow>
            <StyledRow align="center">{formatTime(activity.created_at)}</StyledRow>
            <StyledRow align="center">{activity.customer_name ? activity.customer_name : formatPhoneNumber(activity.phone_number)}</StyledRow>
            <StyledRow align="center">{activity.ticket_number}</StyledRow>
            <StyledRow align="center">
              <Box height="32px" display="flex" alignItems="center" justifyContent="center">
                <StatusTag status={activity.status} />
              </Box>
            </StyledRow>
            {accountData && accountData.single_location === false && (
              <StyledRow align="center">{activity.location}</StyledRow>
            )}
            <StyledRow align="center">
              <StatusIcon status={activity.message_status} />
            </StyledRow>
          </TableRow>
          
        ))}
        
      </TableBody>
    
    </Table>


    <MessageDetails 
    open={isMessageDetailsOpen} 
    selectedRow={selectedRow} 
    onClose={handleCloseMessageDetails}
    handleOpenModal={handleOpenModal}
    setSelectedPhoneNumber={setSelectedPhoneNumber} />

<CreateCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSaveCustomer={handleSaveCustomer}
          selectedPhoneNumber={selectedPhoneNumber}
          location_name={subUserData?.location_name || ''} // Check if subUserData exists before accessing location_name
          />
    
  </TableContainer>
      )}

{!loadingRecentActivity && recentActivityData && recentActivityData.length === 0 && (
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            background: 'radial-gradient(circle, #EEF6F9, #D4E9F2)',
            alignItems: 'center', // Added to center vertically
            height:'69vh',
            borderRadius: '10px',
            overflow: 'hidden', // Ensures no content overflows out of the Box



          }}
        >
          <Box sx={{ textAlign: 'center',  mt:-3, mb:-9 }}> {/* Added container */}
          <CardMedia
        component="img" // Specify that this is an image component
        image={noMessage}
        sx={{
          width: { xs: '60vw', sm: '40vw', md: '30vw', lg: '25vw' }, // Responsive width
          height: 'auto', // Maintain aspect ratio
          maxWidth: '485px', // Limit maximum size
          maxHeight: '100%', // Ensures the image doesn't exceed the container height
          maxWidth: '100%', // Ensures the image doesn't exceed the container width
          objectFit: 'contain', // Keeps the image within the box, maintaining aspect ratio

        }}
      />
            
            
          </Box>
          <Typography
            align="center"
            sx={{
              fontSize: 18,
              color: '#447c92',
              mb:'15px',
     
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            No Recent Activity Yet.
          </Typography>
          <Typography
                    align="center"
                    sx={{
                      fontSize: 15,
                      color: '#447c92',
                      mb:'20px',
             
                      textAlign: 'center',
                      fontWeight: 600,
                    }}
                  >
            Try sending a few notifications to your customers....your recent activity will automatically show here.
          </Typography>
        </Box>
        
        )}
    </Paper>
  );
}
