import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
Box,
DialogTitle,
Dialog,
DialogContent,
Typography,
IconButton,
Paper,
Button,
Stack
} from '@mui/material';
import { useAccountContext } from '../../../../context/AccountContext'; // Import AppProvider from AccountContext
import DetailsIcon from '../../../../public/assets/icons/icons/details.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import FailIcon from '../../../../public/assets/icons/icons/failedMessage.png';
import StatusText from '../../../color-utils/StatusText';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import {useAuth0} from '@auth0/auth0-react';    
import { useAppContext } from 'src/context/AppContext';
import ReviewDialog from 'src/components/modal/ReviewDialog/ReviewDialog';
import useDowngradeToSingleUser from 'src/components/payments/hooks/useDowngradeToSingleUser.js'
import useDowngradeToMultiUser from 'src/components/payments/hooks/useDowngradeToMultiUser';











function DowngradeDialog({ open, onClose, itemToDelete, fetchUsers, title, downgradeDialogTitle, isDowngradingLocation , locationToDelete, dialogSubText, downgradeError, isDowngradingToMultiUser,   }) {

  const [openReviewDialog, setOpenReviewDialog] = useState(false);  

    
  const { downgradeToSingleUser } = useDowngradeToSingleUser();
  const { downgradeToMultiUser } = useDowngradeToMultiUser();
  const [newPlanName, setNewPlanName] = useState(''); 
  const [newPlanFeatureOne, setNewPlanFeatureOne] = useState(''); 
  const [newPlanFeatureTwo, setNewPlanFeatureTwo] = useState('');
  const [newPlanFeatureThree, setNewPlanFeatureThree] = useState('');
  const [oldPlanName, setOldPlanName] = useState(''); 
  const [oldPlanFeatureOne, setOldPlanFeatureOne] = useState('');
  const [oldPlanFeatureTwo, setOldPlanFeatureTwo] = useState('');
  const [oldPlanFeatureThree, setOldPlanFeatureThree] = useState('');
  const [oldPlanPrice, setOldPlanPrice] = useState(''); 
  const [newPlanPrice, setNewPlanPrice] = useState(''); 
  const [buttonAction, setButtonAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);  








  const handleCloseReviewDialog = () => { 
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {  
    setOpenReviewDialog(true);
  };





  useEffect(() => {
    if (open && !isDowngradingLocation) {
      // Call the function directly inside the useEffect when the conditions are met
      setOldPlanPrice('$9.99/month');
      setNewPlanPrice('$4.99/month');
      setNewPlanName('Single User Plan'); 
      setNewPlanFeatureOne('Customizable Notifications');
      setNewPlanFeatureTwo('Two Way Notification Support');
      setNewPlanFeatureThree('Scheduled Notification Support');
      setOldPlanName('Multi User Plan');
      setOldPlanFeatureOne('Includes 2 Users');
      setOldPlanFeatureTwo('Additional User $5/month');
      setOldPlanFeatureThree('Add / Remove Users Anytime');
      
    }
  }, [open, isDowngradingLocation]); // Add isDowngradingLocation to the dependency array


  useEffect(() => {
    if (open && isDowngradingLocation) {
      // Call the function directly inside the useEffect when the conditions are met
      setOldPlanPrice('$19.99/month');
      setNewPlanName('Multi User Plan');
      setNewPlanFeatureOne('Includes 2 Users & 2 Locations');
      setNewPlanFeatureTwo('Additional User $5/month');
      setNewPlanFeatureThree('Additional Location $10/month');
      setOldPlanName('Multi Location Plan');
      setNewPlanPrice('$9.99/month');
      setOldPlanFeatureOne('Unlimited Notifications');
      setOldPlanFeatureTwo('Two Way Notification Support');
      setOldPlanFeatureThree('Scheduled Notifications');
      
    }
  }, [open, isDowngradingLocation]); // Add isDowngradingLocation to the dependency array






useEffect(() => { 
  if (!open) {
    setOpenReviewDialog(false);
  }
}, [open]);


    const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently from the hook  
    const { accountData, updateSingleUserStatus, updateSingleLocationStatus } = useAccountContext(); // Use the accountData from the context
    const {showSnackBar} = useAppContext(); // Use the showSnackbar function from the context 




    




    const handleDeleteUser = async () => {
      setIsLoading(true); 
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently(); // Ensure you have the access token
          
      
          const deleteUserResponse = await fetch(`${apiUrl}/api/users/downgrade/${itemToDelete.user_id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: accountData.id }) // Include account ID in the body

          });
      
          if (deleteUserResponse.ok) {
            await downgradeToSingleUser();
            showSnackBar('User Deleted. Account Downgrade Complete.', true);

            window.location.reload();  // This refreshes the whole page
            onClose(); // Close the dialog
       

            return true; // Indicate success
          } else {
            const errorData = await deleteUserResponse.json();
            showSnackBar('Failed to delete user. Please try again', false);
            setIsLoading(false);

            return false; // Indicate failure
          }
        } catch (error) {
          setIsLoading(false);
          showSnackBar('Failed to delete user. Please try again', false);
          return false; // Indicate failure
        }
      };




      const handleDeleteLocation = async () => {
        setIsLoading(true);
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently(); // Ensure you have the access token
      
          const deleteLocationResponse = await fetch(`${apiUrl}/api/locations/downgrade/${locationToDelete.location_id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: accountData.id }) // Include account ID in the body

          });
      
          if (deleteLocationResponse.ok) {
            await downgradeToMultiUser();
            showSnackBar('Location Deleted. Account Downgrade Complete.', true);
            window.location.reload();  // This refreshes the whole page

            

            

            onClose(); // Close the dialog
            return true; // Indicate success
          } else {
            const errorData = await deleteLocationResponse.json();
            showSnackBar('Failed to delete location. Please try again', false);
            setIsLoading(false);
            return false; // Indicate failure
          }
        } catch (error) {
          showSnackBar('Failed to delete location. Please try again', false);
          setIsLoading(false);
          return false; // Indicate failure
        }
      };



      const handleDelete = async () => {
        if (isDowngradingLocation) {
          return await handleDeleteLocation();
        } else {
          return await handleDeleteUser();
        }
      };  




    


  return (
    <Dialog open={open} onClose={onClose} 
    PaperProps={{
        style: {  backgroundColor: '#747C86', width:'auto', border: '1px solid rgba(173, 216, 230, 0.7)', minWidth: '400px',borderRadius: '18px',bottom: '5%',maxwidth:'600px',
          

        },
      }}
      sx={{

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "25px",
          height: "25px",
          padding: "3px",

        }}
      >
        <CloseIcon sx={{fontSize: "28px"}} />
      </IconButton>
      <DialogTitle
        sx={{

          width:'100%',
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#FFFFFF",
          padding:2,
          flexDirection: "column",

          color: "#374247",

        }}
      >
        {!downgradeError && (

        <Box >

            <img
          src={DetailsIcon}
          alt="detailsIcon"
          style={{
            border: '1.25px solid rgba(173, 216, 230, 0.7)',
            width: "auto",
            height: "29px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",

          }}
        />
</Box>
        )}
                {!downgradeError && (
                  <>

<Typography  sx={{ color: "#48768C" ,  mb:.5, fontWeight:600,marginRight:'auto', mt:1, lineHeight:'-2%', letterSpacing:'-2%', fontSize:'16px' }}>
          Change Account Type {" "}
        </Typography>
        </>
)}
      </DialogTitle>
      <DialogContent
        sx={{         
          mt:-1,
          fontWeight: 600,
          letterSpacing: '0.02em',
          backgroundColor: "#FFFFFF",
          color: '#48768C',
          padding:'.75rem'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row',alignItems:'left', backgroundColor: 'rgba(191, 218, 231, 0.1)',
border: '1px solid rgba(173, 216, 230, 0.7)', borderRadius:'10px',  fontSize:15, justifyContent:'space-between',padding:'.25rem', pt:1, mt:1  }}>            
        <Box sx={{ borderRadius: '10px',  borderRadius: '6px',width:'auto', pl:.25,padding:1  }}>         
        <Box>
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',  }}>
  <Typography sx={{fontSize:'14px'}}>
  <strong>{downgradeDialogTitle}  </strong>
    </Typography>
  </Box>
            </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', mt:1.5,  }}>
   
             <Typography sx={{fontSize:'14px'}}>
              <strong>             {dialogSubText} 
              </strong>
             </Typography>
         </Box>
         {!downgradeError ? (  
         <Stack direction="row">
         <Button  sx={{
                display: 'flex',
                border: '1px solid #48768C',
                borderRadius: '10px',
                padding: '8px',
                color: '#48768C',
                backgroundColor: '#FFFFFF',
                ml:'auto',  
                mr:6,  
                mt: 2,
                mb:1,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#DBEAF0',
                },
                '&:active': {
                  backgroundColor: '#DBEAF0',
                },
              }}
              color="primary"
            >
            Cancel
          </Button>
          <Button 
          onClick={handleOpenReviewDialog}    
          sx={{
                display: 'flex',
                border: '1px solid #48768C',
                borderRadius: '10px',
                padding: '8px',
                color: '#48768C',
                backgroundColor: '#FFFFFF',
                mr:2,
                mt: 2,
                mb:1,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#DBEAF0',
                },
                '&:active': {
                  backgroundColor: '#DBEAF0',
                },
              }}
              color="primary"
            >
            Continue
          </Button>
          </Stack>
         ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt:1.5,  }}>
            </Box>
          )}
        </Box>
        </Box>
      </DialogContent>
      <ReviewDialog
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        addedUserPrice='+ $5/month'
        buttonText='Confirm & Downgrade Plan'
        changedPlan={true}
        buttonAction={handleDelete}  
       
        plan='singleUserMonthly'
        buttonLoadingText='Saving Changes...'
        newPlanName={newPlanName}
        newPlanFeatureOne={newPlanFeatureOne}
        newPlanFeatureTwo={newPlanFeatureTwo}
        newPlanFeatureThree={newPlanFeatureThree}
        oldPlanName={oldPlanName}
        oldPlanFeatureOne={oldPlanFeatureOne}
        oldPlanFeatureTwo={oldPlanFeatureTwo}
        oldPlanFeatureThree={oldPlanFeatureThree}
        oldPlanPrice={oldPlanPrice}
        newPlanPrice={newPlanPrice}
        isLoading={isLoading}
        

/>
    </Dialog>
  );
}

export default DowngradeDialog; 