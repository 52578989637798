import React from 'react';  
import { Box, Typography, Stack, Chip } from '@mui/material';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';



export const RenderSelectedField = (fieldType) => {
    switch (fieldType) {
      case 'DATE':
        return (
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography sx={{marginRight:'auto',  alignContent:'center',mt:.25, fontWeight: 600, fontSize: "15px", color:'#747C86', ml:1, mr:1, whiteSpace:'noWrap'}}>MM/DD/YYYY</Typography>  
            <CalendarMonthTwoToneIcon className="change_field_icon" />
          </Box>
        );
    case 'TIME':
        return (
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography sx={{marginRight:'auto',  alignContent:'center',mt:.25, fontWeight: 600, fontSize: "15px", color:'#747C86', ml:1, mr:1, whiteSpace:'noWrap'}}>HH:MM   AM/PM</Typography>  
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Box>
        );
    case 'DATE/TIME':
        return (
          <Stack  direction="row" spacing={1} style={{ marginRight:'auto',}}>

            <Typography sx={{marginRight:'auto',  alignContent:'center',mt:.25, fontWeight: 600, fontSize: "15px", color:'#747C86', ml:1, mr:1, whiteSpace:'noWrap'}}>MM/DD/YYYY</Typography>  
            <CalendarMonthTwoToneIcon className="change_field_icon" />
            <Typography sx={{marginRight:'auto',  alignContent:'center',mt:.25, fontWeight: 600, fontSize: "15px", color:'#747C86', ml:1, mr:1, whiteSpace:'noWrap'}}>HH:MM   AM/PM</Typography>  
            <QueryBuilderTwoToneIcon className="change_field_icon" />
            
          </Stack>
          
        );
    case 'CUR':
        return (
          <Typography sx={{marginRight:'auto',mt:.35, fontWeight: 600, fontSize: "15px", color:'#747C86', ml:1, mr:1}}>$0.00</Typography>
        );
    case 'STR':
        return (
          <Box >
                        <CalendarMonthTwoToneIcon className="change_field_icon" />

            
         </Box>
        );
    case 'TMR':
        return (
          <Stack  direction="row" spacing={1} style={{ marginLeft:'auto',flexGrow: 1,justifyContent:'flex-end',marginLeft:'auto', marginRight:'10px'}}>


                <Chip label="Days" sx={{fontWeight: 600, fontSize: "12px", color:'#747C86'}} />
                <Chip label="Hours" sx={{fontWeight: 600, fontSize: "12px", color:'#747C86'}} />
                <Chip label="Minutes" sx={{fontWeight: 600, fontSize: "12px", color:'#747C86'}} />
              </Stack>
            );
        default:
            return null;
    }
  };
