import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, Table, TableHead, TableRow, TableCell, TableBody, LinearProgress, Typography, Box, TableContainer, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../context/AuthContext';
import { useAccountContext } from '../context/AccountContext'; // Import AppProvider from AccountContext
import ProfileIcon from '../public/assets/icons/icons/help-center/user.png';
import { useUserSelectedNotifications } from '../context/UserNotificationsContext.js';
import GenerateColor from 'src/components/color-utils/generateColor';
import { useReportsContext } from '../context/ReportsContext';
import MultiUserUpgrade from '../components/Account-Upgrades/components/MultiUserUpgrade/multiUserUpgrade';
import MultiLocationUpgrade from "../../src/components/Account-Upgrades/components/MultiLocationUpgrade/multiLocationUpgrade.js";



export function Reports() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { username, subUserData } = useAuth();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [totalMessages, setTotalMessages] = useState(0);
  const [loading, setLoading] = useState(true);
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const isSingleUser = accountData?.single_user; // Check if single_user is true in accountData
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const [userData, setUserData] = useState({});
  const { selectedNotifications } = useUserSelectedNotifications();
  const { statusTotals,notificationTotals,totalsByLocation,userReportsData, totalUsers, totalLocations } = useReportsContext();
  const [multiUserUpgradeDialogOpen, setMultiUserUpgradeDialogOpen] = useState(false);   
  const [users, setUsers] = useState([]);
  const [adminCount, setAdminCount] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);


  const [multiLocationUpgradeDialogOpen, setMultiLocationUpgradeDialogOpen] = useState(false);   



const handleMultiLocationUpgradeDialogOpen = () => {
  setMultiLocationUpgradeDialogOpen(true);
};

const handleMultiLocationUpgradeDialogClose = () => {
  setMultiLocationUpgradeDialogOpen(false);
};




const handleMultiUserUpgradeDialogOpen = () => {
  setMultiUserUpgradeDialogOpen(true);
};

const handleMultiUserUpgradeDialogClose = () => {
  setMultiUserUpgradeDialogOpen(false);
};





const fetchUsers = async () => {
  if (multiLocationUpgradeDialogOpen) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      // Ensure data.users is an array, then map through it to update the structure
      const usersData = Array.isArray(data.users)
        ? data.users.map((user) => ({
            ...user,
            location_name: user.assigned_location, // Adjust if necessary based on server response
          }))
        : [];

      const adminCount = usersData.filter((user) => user.is_admin).length;

      // Assuming you have state hooks for these:
      setUsers(usersData);
      setAdminCount(adminCount);
      setLoadingUsers(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }
};

useEffect(() => {
  fetchUsers();
}, []);


  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scrollbar
    return () => {
      document.body.style.overflow = ''; // Re-enable scrollbar on component unmount
    };
  }, []);


  return (
<Box
				sx={{
     width: 'auto',  mx:'4px', borderRadius: '10px',
     overflowY: 'auto',
 maxHeight: 'calc(100vh - 125px)' , 			
					backgroundColor: '#FFFFFF',
          boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)',
mt:-.5
          
				}}>


              <Typography    sx={{   pt: 1.5,
      color: '#48768C',
      fontWeight: 700,
      fontSize: 17,
      backgroundColor: 'rgba(255, 255, 255, 1.9)',
      borderBottom: 'none',
      pl: '16px',
      mb:'2px'}}>
                Usage Reports
              </Typography>
              <Typography sx={{ ml: 2, color: '#48768C', fontSize:15, borderBottom: 'none', mb:2 }}>
                Your current monthly usage and account activity
              </Typography>
              
             
            <Box sx={{ backgroundColor: 'rgba(173, 216, 230, 0.2)', borderRadius: '10px',  border: '1.25px solid rgba(173, 216, 230, 0.5)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',mb:5 }}>
        <Typography align="left" sx={{ textAlign: 'left', fontWeight: 700, width: '100%', color: '#48768C', fontSize: 15, pt: 2, pl: 2, pb: 2 }}>Notifications Sent This Month</Typography>
        {Object.keys(notificationTotals || {})
  .sort((a, b) => notificationTotals[b] - notificationTotals[a])
  .map((notificationType, index) => {
    const total = notificationTotals[notificationType] || 0;
    const colorProps = GenerateColor({ selectedNotifications, notificationType }); // Generate color props

    return (
      <Box key={notificationType} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ backgroundColor: colorProps.titleColor, width: '15%', fontSize: '16px', fontWeight: 600, color: 'white', fontSize: 13, padding: '8px', textAlign: 'center', borderRadius: '6px', mr: 2, ml: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
          {notificationType}
        </Typography>
        <LinearProgress sx={{ width: '80%', color: '#48768C', '& .MuiLinearProgress-bar': { backgroundColor: colorProps.titleColor }, backgroundColor: colorProps.hoverColor, borderRadius: '12px' }} variant="determinate" value={parseFloat(total)} />
        <Typography sx={{ color: '#48768C', fontWeight: 600, fontSize: 15, padding: '10px', textAlign: 'center', ml: 2, mr: 2 }}>
          {total}
        </Typography>
      </Box>
    );
  })}
<Box sx={{height:'auto', pt:3, pb:0 }}>
<Box>
<TableContainer
                sx={{borderRadius:'10px',border: '1.25px solid rgba(173, 216, 230, 0.5)' }}
                >  
 

  <Box>


                  <Table sx={{ width: '100%',borderRadius:'12px',borderBottomLeftRadius:'12px', bottomRightRadius:'12px', }}>
                    <TableHead>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ padding: 1.5, backgroundColor: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', borderBottom: 'none', ml: 0.5,}}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography align="left"sx={{textAlign: 'left',fontWeight: 700,width: '100%',color: '#48768C',fontSize: 15,pt:'1px',pb: '1px'}}>Notifications Sent By Employee</Typography>

      </div>
    </TableCell>
  </TableRow>
          <TableRow>

          <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600, py:1,borderBottom:'none' }}>Name</TableCell>
            <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1,borderBottom:'none'}}>Username</TableCell>
            {!accountData.single_location && (
    <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1,borderBottom:'none'}}>Assigned Location</TableCell>
  )}
            <TableCell sx={{ textAlign: 'left',  backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1,borderBottom:'none' }}>Total Notifications Sent This Month</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody
    sx={{
      position: 'relative',
      filter: accountData.single_user ? 'blur(5px)' : 'none',
      zIndex: 1,
    }}
  >
      {accountData && !accountData.single_user && userReportsData.length > 0 ? (
      // Data available state
      userReportsData.map((userData, index) => (
        <TableRow key={index} sx={{}}>
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)',borderBottom:'none' }}>{userData.full_name}</TableCell>
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)',borderBottom:'none' }}>{userData.username}</TableCell>
        {!accountData.single_location && (

        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)',borderBottom:'none' }}>{userData.assigned_location}</TableCell>
        )}
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)', borderBottom:'none'  }}>{userData.total_messages_sent}</TableCell>
        </TableRow>
      ))
    ) : (
      // No data available state
      <TableRow key="no-data">
        <TableCell colSpan={5} sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>{accountData && !accountData.single_user ? '' : ''}</TableCell>
      </TableRow>
    )}
  </TableBody>
  <TableBody> 
  {!accountData.single_location && (  
  <TableRow>
        <TableCell colSpan={5} sx={{ padding:0, pb:'10px',pt:-1,textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)',borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px',borderBottom:'none' }}></TableCell>
      </TableRow>
      )}

      </TableBody>
      </Table>
  </Box>
  {accountData.single_user && ( 

  <Box
sx={{
  zIndex: 3, // ensure it's above the table and blur effect
  padding: 4,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  maxWidth: '500px',
  display: 'flex',          // make the Box a flex container
  justifyContent: 'center', // horizontally center the content
  alignItems: 'center',     // vertically center the content
  top: '50%',               // position at 50% from the top
  left: '50%',              // position at 50% from the left
  transform: 'translate(-0%, -13%)', // adjust positioning to ensure centering
  margin: '0 auto', // centers the Box horizontally
  flexDirection: 'column',
  backgroundColor: '#D1DDE7',
  border: '1.25px solid rgba(173, 216, 230, 0.5)',
  borderRadius: '14px',
}}
>
<Typography  sx={{ mb: .55 , color:'#48768C', letterSpacing:'-2%', fontWeight:'600', fontSize:'15px'}}>
  NanoNotify works great with multiple users! 
</Typography>
<Typography  sx={{ mb: 2 , color:'#48768C', letterSpacing:'-2%', fontWeight:'600', fontSize:'15px'}}>
  Upgrade your account to access this feature.
</Typography>

</Box>
)}
<MultiUserUpgrade
      multiUserUpgradeDialogOpen={multiUserUpgradeDialogOpen}
      handleMultiUserUpgradeDialogClose={handleMultiUserUpgradeDialogClose}
      users={users} 
    />
        
</TableContainer>
              </Box>
            </Box>
           
            <Box sx={{ height:'auto', mt:5 }}>
              <Box>
                <TableContainer
                sx={{borderRadius:'10px',border: '1.25px solid rgba(173, 216, 230, 0.5)' }}
                >  

                <Table sx={{ width: '100%',borderRadius:'12px',borderBottomLeftRadius:'12px', bottomRightRadius:'12px', }}>
                  <TableHead>
                 <TableRow>
                  <TableCell colSpan={5} sx={{ padding: 1.5, backgroundColor: '#FFFFFF', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', borderBottom: 'none', ml: 0.5,}}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Typography align="left"sx={{textAlign: 'left',fontWeight: 700,width: '100%',color: '#48768C',fontSize: 15,pt:'1px',pb: '1px'}}>Notifications Sent By Location</Typography>

    </div>
  </TableCell>
</TableRow>
                    <TableRow
                    >
                      <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1, borderBottom:'none' }}>Location</TableCell>
                      <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1,borderBottom:'none' }}>Total Employees</TableCell>
                      <TableCell sx={{ textAlign: 'left', backgroundColor: 'rgba(173, 216, 230, 0.3)', color: '#48768C', fontWeight:600,py:1,borderBottom:'none' }}>Total Notifications Sent This Month</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody
  sx={{
    position: 'relative',
    filter: accountData.single_location ? 'blur(5px)' : 'none',
    zIndex: 1,
  }}
>

  {accountData && !accountData.single_location && totalsByLocation.length > 0 ? (
    // Data available state
    totalsByLocation.map((locationData, index) => (
      <TableRow 
      sx={{borderRadius:'10px',}}
      
      key={index}>
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>{locationData.location}</TableCell>
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>{locationData.employee_count}</TableCell>
        <TableCell sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>{locationData.message_count}</TableCell>
      </TableRow>
    ))
  ) : (
    // No data available state
    <TableRow key="no-data">
      <TableCell colSpan={3} sx={{ textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)' }}>{accountData.single_location ? '' : 'No Data'}</TableCell>
    </TableRow>
  )}
</TableBody>
<TableBody> 
  {!accountData.single_location &&  (  
<TableRow>
      <TableCell colSpan={5} sx={{ padding:0, pb:'10px',pt:-1,textAlign: 'left', color: '#48768C', backgroundColor: 'rgba(173, 216, 230, 0.2)',borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px',borderBottom:'none' }}></TableCell>
    </TableRow>
    )}

    </TableBody>


                </Table>
                {accountData.single_location && ( 
                <Box
  sx={{
    zIndex: 3, // ensure it's above the table and blur effect
    padding: 4,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '500px',
    display: 'flex',          // make the Box a flex container
    justifyContent: 'center', // horizontally center the content
    alignItems: 'center',     // vertically center the content
    top: '50%',               // position at 50% from the top
    left: '50%',              // position at 50% from the left
    transform: 'translate(-0%, -15%)', // adjust positioning to ensure centering
    margin: '0 auto', // centers the Box horizontally
    flexDirection: 'column',
    backgroundColor: '#D1DDE7',
    border: '1.25px solid rgba(173, 216, 230, 0.5)',
    borderRadius: '14px',
    
  }}
>
  <Typography  sx={{ mb: .55 , color:'#48768C', letterSpacing:'-2%', fontWeight:'600', fontSize:'15px'}}>
    NanoNotify works great for teams with multiple locations! 
  </Typography>
  <Typography  sx={{ mb: 2 , color:'#48768C', letterSpacing:'-2%', fontWeight:'600', fontSize:'15px'}}>
    Upgrade your account to access this feature.
  </Typography>
</Box>
)}




                </TableContainer>

                </Box>
              </Box>
              <MultiUserUpgrade
      multiUserUpgradeDialogOpen={multiUserUpgradeDialogOpen}
      handleMultiUserUpgradeDialogClose={handleMultiUserUpgradeDialogClose}
      users={users} 
    />

              <MultiLocationUpgrade
      multiLocationUpgradeDialogOpen={multiLocationUpgradeDialogOpen}
      handleMultiLocationUpgradeDialogClose={handleMultiLocationUpgradeDialogClose}
      users={users} 
    />

                

    </Box>
                    
       


    </Box>

  );
}


export const ProtectedMessagesFeed = withAuthenticationRequired(Reports, {
  // Custom options, if needed
  // For example, onRedirecting, returnTo, etc.
});

export default Reports;


