import React, { useState, useEffect } from 'react';
import { Box, Popover, Typography, Stack } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import { generateColor } from './generateColor'; // Import the function that generates the color
import useColorPicker from './useColorPicker';

const ColorPicker = ({
  colorPickerOpen,
  colorPickerAnchorEl,
  handleColorPopoverClose,
  handleIconClick,
}) => {

  // Notify parent component of the color change
  const handleColorChange = (color) => {
    const newColors = handleIconClick(color);
    handleColorPopoverClose();
  };






  return (
    <Popover
      open={colorPickerOpen}
      anchorEl={colorPickerAnchorEl}
      onClose={handleColorPopoverClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          p: 0,
          mt: 'auto',
          ml: 'auto',
          width: 'auto',
          backgroundColor: '#F2F9FF',
          border: '1px solid rgba(173, 216, 230, 0.5)',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', padding: 0, }}
        >
          {/* First row of color options */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              alignItems: 'center',
        
            }}
          >

              <>
              <Stack direction="row" spacing={.75}>
                <Box sx={{border:'1px solid #647D9E', alignItems:'center', display:'flex', borderRadius:'4px'}}>
                <SquareIcon className="_2BBB9E" onClick={() => handleColorChange('#2BBB9E')} />
                </Box>
            <Box sx={{border:'1px solid #647D9E', alignItems:'center', display:'flex', borderRadius:'4px'}}>

            <SquareIcon className="_7589A2" onClick={() => handleColorChange('#7589A2')} />
            </Box>
            <Box sx={{border:'1px solid #647D9E', alignItems:'center', display:'flex', borderRadius:'4px'}}>

            <SquareIcon className="_a1848f" onClick={() => handleColorChange('#a1848f')}  />
            </Box>
            <Box sx={{border:'1px solid #647D9E', alignItems:'center', display:'flex', borderRadius:'4px'}}>

            <SquareIcon className="_8C4350" onClick={() => handleColorChange('#8C4350')} />
            </Box>
            </Stack>
            </>
          </Box>

          {/* Add more color options here */}
          {/* 
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              gap: '5px',
              mb: '5px',
            }}
          >
            <SquareIcon className="_827b76" />
            <SquareIcon className="_a3847d" />
            <SquareIcon className="_858ba9" />
            <SquareIcon className="_a89a8c" />
            <SquareIcon className="_808a7c" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              gap: '5px',
              mb: '5px',
            }}
          >
            <SquareIcon className="_9f7f75" />
            <SquareIcon className="_827d89" />
            <SquareIcon className="_7d8585" />
            <SquareIcon className="_a97d75" />
            <SquareIcon className="_828ca0" />
          </Box>
          */}
        </Box>
      </Box>
    </Popover>

    
  );
};

export default ColorPicker;