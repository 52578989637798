// SingleUserContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from './AuthContext';
import { useAccountContext } from '../context/AccountContext'; // Import useAccountContext from AccountContext

const SingleUserContext = createContext();

export const SingleUserProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { setSubUserData: setAuthSubUserData } = useAuth(); // Import setSubUserData from AuthContext

  const [subUserData, setSubUserData] = useState({
    user_id: '',
    auth0_user_id: '',
    username: '',
    pin: '',
    first_name: '',
    last_name: '',
    active_location: '',
    title: '',
    email: '',
    location_id: '',
    location_name: '',
    assigned_location: '',
    location_email: '',
    location_phone: '',
    is_admin: '',
  });

  const [userData, setUserData] = useState({});
  const [reloadComponents, setReloadComponents] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [username, setUsername] = useState('');
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const apiUrl = process.env.REACT_APP_API_URL;


  const updateSubUserData = (newSubUserData, newUserData) => {
    setLoggedIn(true);
    setUserVerified(true);
    setUsername(newSubUserData.username);
    setSubUserData(newSubUserData);
    setUserData(newUserData);
    setAuthSubUserData(newSubUserData); // Update AuthContext's subUserData

  };


  const fetchLatestSingleUserData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (accountData.single_user && !subUserData.username) {
        const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        
        const fetchedUserData = await response.json();
  
        if (response.ok && fetchedUserData) {
  
          if (!isEqual(fetchedUserData, subUserData)) {
            // Update the username state
            setUsername(fetchedUserData.username);
  
            setSubUserData(fetchedUserData);
            setUserData(fetchedUserData.users[0]); // Update userData directly
            setAuthSubUserData(fetchedUserData.users[0]); // Update AuthContext's subUserData
          }

        } else {
          console.error('Failed to fetch single user data from API');
        }
      }
    } catch (error) {
      console.error('Error fetching single user data from API:', error);
    
  }
  };
  


  useEffect(() => {

  }, [subUserData, userData]);





  

  const contextValue = {
    subUserData,
    userData,
    fetchLatestSingleUserData,
    updateSubUserData,
    reloadComponents,
    loggedIn,
    userVerified,
    username,
    setSubUserData,
    setUserData
  };

  return (
    <SingleUserContext.Provider value={contextValue}>
      {children}
    </SingleUserContext.Provider>
  );
};

export const useSingleUser = () => {
  const context = useContext(SingleUserContext);
  if (!context) {
    throw new Error('useSingleUser must be used within a SingleUserProvider');
  }
  return context;
};