import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Icon, IconButton, InputAdornment  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../../../context/AuthContext.js'; // Assuming subUserData is coming from this context  
import { useAccountContext } from 'src/context/AccountContext';
import { sub } from 'date-fns';
import StepOne from './StepOne.js';  
import StepTwo from './StepTwo.js';  
import StepThree from './StepThree.js';
import StepFour from './StepFour.js';
import Review from './Review.js';
import { set } from 'lodash';
import companyImage from 'src/public/assets/multiLocation.png';
import completeImage from 'src/public/assets/upgradeComplete.png';
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from 'react-router-dom';
import useUpgradeToMultiLocation from 'src/components/payments/hooks/useUpgradeToMultiLocation';
import './styles.css';




  






const MultiLocationUpgradeStepper = (users, isDialogClosing) => {
  const [activeStep, setActiveStep] = useState(0);
  const { user, getAccessTokenSilently } = useAuth0();  
  const {subUserData, username, logout: customLogout, resetContext} = useAuth();
  const { handleReloadComponents , showSnackBar} = useAppContext();
  const { accountData } = useAccountContext();  
  const apiUrl = process.env.REACT_APP_API_URL; 
  const navigate = useNavigate(); // Initialize the hook
  const {upgradeToMultiLocation} = useUpgradeToMultiLocation();




  const handleLogout = () => {
    localStorage.clear();
    resetContext(); 
    navigate('/'); 
    window.location.reload();
  };




  const steps = [
    'Upgrade To A Multi-Location Account',
    'Add your new location',
    accountData.single_user ? 'Create your location\'s first employee' : 'Assign employees to your new location',
    'Update Exsisting Location Info',

    ...(accountData.single_user ? ['Create Your Username and Pin'] : []),
    'Review'
  ];

  const handleNext = async () => {
    let nextStep = activeStep + 1;
  
    // Special case to skip step 4 if it's not required
    if (!accountData.single_user && activeStep === 3) {
      nextStep = 4;
    }
  
    // If moving to step 4 or step 5, trigger the upgrade process
    if ((accountData.single_user && activeStep === 5) || (!accountData.single_user && activeStep === 4)) {
      const upgradeSuccess = await handleMultiLocationUpgradeSubmit();
  
      // If upgrade fails, exit early and do NOT advance to the next step
      if (!upgradeSuccess) {
        return; // Stop execution and don't advance
      }
    }
  
    // Only reach this point if no failure happened during the upgrade
    setActiveStep(nextStep);
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };



  const isNextButtonDisabled = () => {  
    switch (activeStep) {
      case 0:
        return false; // Step 1, button is always enabled
      case 1:
        return !isFormValid; // Step 2, enabled if form is valid
      case 2:
        return !(right.length > 0 || isSaveUserButtonEnabled); // Step 3, enabled if more than 1 user OR `isSaveUserButtonEnabled`
      case 3:
        return !updatedName || locationNameTaken; // Step 4, enabled if `updatedName` has a value
        case 4:
          // Step 5: If `single_user` is false, button is always enabled
          if (!accountData.single_user) {
            return false; // Button always enabled
          } else {
            return !isUpdateUserButtonEnabled; // When single_user is true, use `isSaveUserButtonEnabled`
          }
      case 5:
        return false; // Step 6, button always enabled
      default:
        return true; // Default, button disabled if unknown step
    }
  };


  const handleMultiLocationUpgradeSubmit = async () => {
    try {
      const userSub = accountData.auth0_user;
      const token = await getAccessTokenSilently();
  
  
      const response = await fetch(`${apiUrl}/accounts/multi-location-upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          firstName,
          lastName,
          userName,
          pin,
          newUserName,
          userId: subUserData.user_id,
          locationId: subUserData.active_location,
          userSub,
          updatePin,
          right,
          updatedName,
        }),
      });
  
      if (response.ok) {
        const result = await response.json();
        await upgradeToMultiLocation();
        showSnackBar('Account Upgrade Complete', true);
        return true;
      } else {
        showSnackBar('Account Upgrade Failed. Please Try Again.', false);
        return false;
      }
    } catch (error) {
      showSnackBar('Account Upgrade Failed. Please Try Again.', false);
      return false;
    }
  };






//New Location Logic
  const [name, setName] = useState('');
  const [updatedName, setUpdatedName] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [updatedNameTouched, setUpdatedNameTouched] = useState(false);

  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [confirmNewPin, setConfirmNewPin] = useState('');
  const [isAssignEmployeeVisible, setIsAssignEmployeeVisible] = useState(false);
  const [isMoveUserVisible, setIsMoveUserVisible] = useState(false);  
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [isLocationButtonSaved, setIsLocationButtonSaved] = useState(false);  

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameTouched(true);
  };


  const handleUpdatedNameChange = (e) => {
    setUpdatedName(e.target.value);
    setUpdatedNameTouched(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setPhone(formatPhoneNumber(value));
    setPhoneTouched(true);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2] ? ' ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  useEffect(() => {
    const validateForm = () => {
      const isValidName = name.trim() !== '';
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);
      setIsFormValid(isValidName && isValidEmail && isValidPhone);
    };

    validateForm();
  }, [name, email, phone]);



  const handleSaveLocationClick = async () => { 
    setName(name);
    setEmail(email);
    setPhone(phone);
    setIsLoading(true);  
  };




  //new employee logic
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [newUserName, setNewUserName] = useState(''); 
  const [isSaveUserButtonEnabled, setIsSaveUserButtonEnabled] = useState(false);



  const [userNameTaken, setUserNameTaken] = useState(false);




  const handleTogglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '').substr(0, 4);
    setConfirmNewPin(inputValue);
  };


  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };



  useEffect(() => {
    const isValidInput =
      pin.length >= 4 &&
      pin.length <= 4 &&
      /^\d+$/.test(pin) &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(confirmNewPin) &&
      pin === confirmNewPin &&
      userNameTaken === false &&  
      userName.trim() !== '';
  
      setIsSaveUserButtonEnabled(isValidInput);
  }, [pin, confirmNewPin, firstName, lastName, newUserName, userNameTaken]);




//Transfer List Logic
  // Split lists between left and right (initially all in left)
  const [checked, setChecked] = useState([]);
  const [right, setRight] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedLocation, setAssignedLocation] = useState(null);
  const availableUsers = users.users.filter((user) => user.user_id !== subUserData.user_id);
  const [left, setLeft] = useState(availableUsers);
  const [isNewUserSaved, setIsNewUserSaved] = useState(false);  

  

  // Helpers to move items between lists
  const handleToggle = (value) => () => {
    // Ensure 'checked' is an array
    if (!Array.isArray(checked)) {
      setChecked([]);
      return;
    }
  
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
  
    if (currentIndex === -1) {
      newChecked.push(value);  // Add the item to checked array
    } else {
      newChecked.splice(currentIndex, 1);  // Remove it from the checked array
    }
  
    setChecked(newChecked);
  };




  const handleCheckedRight = () => {
    const selectedUsers = checked.filter((user) => left.includes(user));
    setRight(right.concat(selectedUsers));
    setLeft(left.filter((user) => !checked.includes(user)));
    setChecked([]);


    // Set the assigned user and location (for now location can be set statically or passed as props)
    if (selectedUsers.length > 0) {
      setAssignedUser(selectedUsers[0]);  // Assuming you assign one user at a time
      setAssignedLocation(name);  // Assuming `name` represents the location

    }
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(checked.filter((user) => right.includes(user))));
    setRight(right.filter((user) => !checked.includes(user)));
    setChecked([]);

    // Reset assigned user and location if user is moved back to the left
    setAssignedUser(null);
    setAssignedLocation(null);

  };






  const handleCreateNewEmployeeClick = async () => {  
    setAssignedUser(null);
    setAssignedLocation(null);  
    setRight([]);
    setIsAssignEmployeeVisible(true);  
  }


  const handleSaveNewUserClick = async () => {  
    setFirstName(firstName);
    setLastName(lastName);
    setNewUserName(newUserName);
    setPin(pin);
    setIsNewUserSaved(true);
  }





  //add username to existing user
const[updatePin, setUpdatePin] = useState('');  
const[confirmUpdateNewPin, setConfirmUpdateNewPin] = useState('');
const[showUpdatePin, setShowUpdatePin] = useState(false);
const[isUserUpdated, setIsUserUpdated] = useState(false); 
const[isUpdateUserButtonEnabled, setIsUpdateUserButtonEnabled] = useState(false);



const [newUserNameTaken, setNewUserNameTaken] = useState(false);





const [locationNameTaken, setLocationNameTaken] = useState(false);  

useEffect(() => {
  const locationNameCheck = name === updatedName; // Check if the name matches updatedName

  setLocationNameTaken(locationNameCheck); // Set the state based on the check

}, [name, updatedName, updatedNameTouched]);









  const handleToggleUpdatePinVisibility = () => {
    setShowUpdatePin(!showUpdatePin);
  };


  const toggleShowConfirmNewUpdatePin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };


  const handleConfirmNewUpdatePinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '').substr(0, 4);
    setConfirmUpdateNewPin(inputValue);
  };


 






  useEffect(() => {
    const isExsistingValidInput =
      updatePin.length >= 4 &&
      updatePin.length <= 4 &&
      /^\d+$/.test(updatePin) &&
      confirmUpdateNewPin.length === 4 &&
      /^\d+$/.test(confirmUpdateNewPin) &&
      updatePin === confirmUpdateNewPin &&
      newUserNameTaken === false &&
      newUserName.trim() !== ''; 
  
    setIsUpdateUserButtonEnabled(isExsistingValidInput);
  }, [updatePin, confirmUpdateNewPin, newUserName, newUserNameTaken, username]);  


  useEffect(() => {
    if (accountData.single_user)
    setIsAssignEmployeeVisible(true);
  }, [accountData.single_location]);





const shouldShowAssignEmployee = () => {  
  if (accountData.single_user) {
    setIsAssignEmployeeVisible(false);
  } else {
    setIsAssignEmployeeVisible(true);
  }
}



useEffect(() => {
  if (isDialogClosing) {
    // Clear all relevant states when the dialog closes
    setName('');
    setEmail('');
    setPhone('');
    setIsFormValid(false);
    setNameTouched(false);
    setEmailTouched(false);
    setPhoneTouched(false);
    setShowConfirmNewPin(false);
    setConfirmNewPin('');
    setIsAssignEmployeeVisible(false);
    setIsMoveUserVisible(false);
    setIsLoading(false);
    setUserName('');
    setFirstName('');
    setLastName('');
    setPin('');
    setShowPin(false);
    setNewUserName('');
    setIsSaveUserButtonEnabled(false);
    setChecked([]);
    setRight([]);
    setAssignedUser(null);
    setAssignedLocation(null);
    setLeft([]);
    setIsNewUserSaved(false);
    setUpdatePin('');
    setConfirmUpdateNewPin('');
    setShowUpdatePin(false);
    setUserNameTaken(false);
    setNewUserNameTaken(false);

    // Optionally, log the clearing action
  }
}, [isDialogClosing]);



useEffect(() => {
  if (newUserName === userName) {
    setNewUserNameTaken(true);  // Username taken, show helper text
  } else {
    setNewUserNameTaken(false); // No issue, hide helper text
  }
}, [newUserName, userName]); // Trigger when newUserName or userName changes

useEffect(() => {
  // Only run this effect if accountData.single_user is false
  if (!accountData.single_user) {
    if (users && Array.isArray(users.users)) {
      const UsernameCheck = users.users.some((user) => user.username === userName);
      setUserNameTaken(UsernameCheck);
    } else {
      console.error('users.users is not an array:', users);
    }
  }
}, [userName, users, accountData.single_user]); // Add accountData.single_user to the dependency array


  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex', // Use flex to manage the layout
        justifyContent: 'center', // Center the stepper
        overflowX: 'auto', // Allow scrolling if the stepper overflows horizontally
        flexDirection: 'column', // Stack the steps vertically
      }}
    >
     <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          width: '100%', // Make the stepper responsive
          display: 'flex',
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              minWidth: '50px', // Set a minimum width to prevent cutting off labels
              flexShrink: 0, // Prevent shrinking of step icons and labels
              '& .MuiStepLabel-label': {
                color: activeStep === index ? '#647D9E' : '#3B708A',
                fontWeight: activeStep === index ? 'bold' : 'normal',
                fontSize: '12px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& .MuiStepIcon-root': {
                color: activeStep === index ? '#647D9E' : '#B0BEC5',
              },
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#72C5B4',
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#647D9E',
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
          <Box sx={{ mt: 2 }}>
          <Typography sx={{mb:-2, mt:3, fontSize:'16.5px', letterSpacing:'-2%', lineHeight:'-2%', fontWeight:600, color:'#647D9E'  }}>Your Account Has Been Successfully Upgraded!</Typography>
          <img src={completeImage}
               alt="Welcome" style={{ display: 'block', margin: 'auto', maxWidth: '65%', maxHeight:'65%' }} />

<Typography sx={{mt:-8, fontSize:'14.5px', letterSpacing:'-2%', lineHeight:'-2%', fontWeight:600, color:'#647D9E' }}>All of your changes can be reviewed from the billing section in your account page </Typography>
<Typography sx={{mt:1, fontSize:'14.75px', letterSpacing:'-2%', lineHeight:'-2%', fontWeight:500, color:'#647D9E' }}>
  
{accountData.single_user
    ? "Your team can now log in using the new username and pin you just created :"
    : "You will need to log back in for your changes to take effect."}
</Typography>


          <Button onClick={handleLogout} sx={{
                display: 'flex',
                border: '1px solid #48768C',
                borderRadius: '10px',
                padding: '8px',
                color: '#48768C',
                backgroundColor: '#FFFFFF',
                ml:'auto',  
                mr:'auto',  
                mt: 4,
                mb:1,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#DBEAF0',
                },
                '&:active': {
                  backgroundColor: '#DBEAF0',
                },
              }}
              color="primary"
            >
            Return To Dashboard
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
        {/* Display the current step content */}


        {/* Conditionally render a Box with a border for specific steps */}
        {activeStep === 0 && (
    <Box sx={{    backgroundColor: '#F2F9FF',
      border: '1.25px solid rgba(173, 216, 230, 0.5)',
      borderRadius: '14px', padding: 2, mt: 2, mb:2, pb:1 }}>
  
  <Typography className="stepper_text_header">
    Multiple Locations. One Account.
  </Typography>
  <Typography className="stepper_text_body">
    Upgrading your account is quick and easy, and only takes a few minutes.
  </Typography>
  
 

  <img src={companyImage}
         alt="Welcome" style={{ display: 'block', margin: 'auto', maxWidth: '50%', maxHeight:'50%' }} />


  
  <Typography className="stepper_text_subbody">
  Assign employees to locations. Each locations info will auto-appear in notifications.
    </Typography>
    <Typography className="stepper_text_subbody2">
  Customize individual location hours, contact info and more with just a few clicks.
    </Typography>
  

</Box>
        )}

{activeStep === 1 && (
        <StepOne
          name={name}
          nameTouched={nameTouched}
          handleNameChange={handleNameChange}
          email={email}
          emailTouched={emailTouched}
          handleEmailChange={handleEmailChange}
          phone={phone}
          phoneTouched={phoneTouched}
          handlePhoneChange={handlePhoneChange}
          validateEmail={validateEmail}
          validatePhoneNumber={validatePhoneNumber}
          isFormValid={isFormValid}
          handleSaveLocationClick={handleSaveLocationClick}
          isSaveButtonEnabled={isSaveButtonEnabled}
          isLoading={isLoading}
        />
      )}

{activeStep === 2 && (
        <StepTwo
          firstName={firstName}
          lastName={lastName}
          userName={userName}
          pin={pin}
          confirmNewPin={confirmNewPin}
          showPin={showPin}
          showConfirmNewPin={showConfirmNewPin}
          isAssignEmployeeVisible={isAssignEmployeeVisible}
          isMoveUserVisible={isMoveUserVisible}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setUserName={setUserName}
          setPin={setPin}
          handleConfirmNewPinChange={handleConfirmNewPinChange}
          handleTogglePinVisibility={handleTogglePinVisibility}
          toggleShowConfirmNewPin={toggleShowConfirmNewPin}
          setIsAssignEmployeeVisible={setIsAssignEmployeeVisible}
          users={users}
          subUserData={subUserData}
          isSaveUserButtonEnabled={isSaveUserButtonEnabled}
          isLoading={isLoading}
          name={name}
          handleToggle={handleToggle}
          checked={checked}
          left={left}
          right={right}
          handleCheckedRight={handleCheckedRight}
          handleCheckedLeft={handleCheckedLeft}
          handleCreateNewEmployeeClick={handleCreateNewEmployeeClick} 
          setNewUserName={setNewUserName} 
          isNewUserSaved={isNewUserSaved}
          setIsNewUserSaved={setIsNewUserSaved}
          handleSaveNewUserClick={handleSaveNewUserClick}
          assignedLocation={assignedLocation}
          setLeft={setLeft}
          userNameTaken={userNameTaken}
          

        />
      )}

    
        {activeStep === 3 && (
        <StepThree
          updatedName={updatedName}
          updatedNameTouched={updatedNameTouched}
          handleUpdatedNameChange={handleUpdatedNameChange}
          email={subUserData.location_email || subUserData.email || ''} 
          emailTouched={emailTouched}
          handleEmailChange={handleEmailChange}
          phone={subUserData.location_phone }
          phoneTouched={phoneTouched}
          handlePhoneChange={handlePhoneChange}
          validateEmail={validateEmail}
          validatePhoneNumber={validatePhoneNumber}
          isFormValid={isFormValid}
          subUserData={subUserData}
          setUpdatePin={setUpdatePin}
          updatePin={updatePin}
          confirmUpdateNewPin={confirmUpdateNewPin}
          showUpdatePin={showUpdatePin}
          handleConfirmNewUpdatePinChange={handleConfirmNewUpdatePinChange}
          toggleShowConfirmNewUpdatePin={toggleShowConfirmNewUpdatePin}
          assignedLocation={assignedLocation}
          right={right}
          locationNameTaken={locationNameTaken}
          

         

        />
      )}

      {accountData.single_user &&  activeStep === 4 && (
        <StepFour
        name={name}
        nameTouched={nameTouched}
        handleNameChange={handleNameChange}
        email={email}
        emailTouched={emailTouched}
        handleEmailChange={handleEmailChange}
        phone={phone}
        phoneTouched={phoneTouched}
        handlePhoneChange={handlePhoneChange}
        validateEmail={validateEmail}
        validatePhoneNumber={validatePhoneNumber}
        subUserData={subUserData}
        setUpdatePin={setUpdatePin}
        updatePin={updatePin}
        confirmUpdateNewPin={confirmUpdateNewPin}
        showUpdatePin={showUpdatePin}
        handleConfirmNewUpdatePinChange={handleConfirmNewUpdatePinChange}
        toggleShowConfirmNewUpdatePin={toggleShowConfirmNewUpdatePin}
        assignedLocation={assignedLocation}
        setNewUserName={setNewUserName}
        isUpdateUserButtonEnabled={isUpdateUserButtonEnabled} 
        newUserNameTaken={newUserNameTaken} 
        newUserName={newUserName} 

       

      />
    )}
    


    {(accountData.single_user && activeStep === 5) || (!accountData.single_user && activeStep === 4) ? (
  <Review
    assignedUser={assignedUser}
    assignedLocation={assignedLocation}
    name={name}
    email={email}
    phone={phone}
    isNewUserSaved={isNewUserSaved}
    firstName={firstName}
    lastName={lastName}
    newUserName={newUserName}
    pin={pin}
    right={right}
    userName={userName}
    accountData={accountData}
    subUserData={subUserData}
    updatedName={updatedName}
    users={users}
    left={left}

  />
) : null}
        


        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          
          <Button disabled={activeStep === 0} onClick={handleBack}  sx={{
                display: 'flex',
                border: '1px solid #48768C',
                borderRadius: '10px',
                padding: '5px',
                color: '#48768C',
                backgroundColor: '#FFFFFF',
                mb: 1,
                mt: -1,
                ml:1,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#DBEAF0',
                },
                '&:active': {
                  backgroundColor: '#DBEAF0',
                },
              }}
              color="primary"
            >
            Back
          </Button>
          <Button onClick={handleNext}
            disabled={isNextButtonDisabled()} // Disable the button based on the function

          sx={{
            
                display: 'flex',
                border: '1px solid #48768C',
                borderRadius: '10px',
                padding: '5px',
                color: '#48768C',
                backgroundColor: '#FFFFFF',
                mb: 1,
                mt: -1,
                ml: 'auto',
                mr:1,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: '#DBEAF0',
                },
                '&:active': {
                  backgroundColor: '#DBEAF0',
                },
              }}
              color="primary"
            >
            {activeStep === steps.length - 1 ? 'Save and Confirm' : 'Next'}
          </Button>
        </Box>
        </Box>
      )}
    </Box>
  );
};

export default MultiLocationUpgradeStepper;