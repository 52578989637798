import { useState } from 'react';
import {handleSubmit} from './handleSaveNewFieldData';  
import { useAuth0 } from '@auth0/auth0-react';  
import { useAppContext } from "src/context/AppContext.js";
import { set } from 'date-fns';




export const useNotificationState = (icon, createNew, setCreateNew) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [notificationTitle, setNotificationTitle] = useState('');
  const [printedFieldTitle, setPrintedFieldTitle] = useState('Create New Notification Type');
  const [requiredFieldName, setRequiredFieldName] = useState('');
  const [requiredFieldDataSaved, setRequiredFieldDataSaved] = useState(false);
  const [optionalFieldDataSaved, setOptionalFieldDataSaved] = useState(false);
  const [optionalFieldName, setOptionalFieldName] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [optionalIcon, setOptionalIcon] = useState(null); 
  const [optionalSelectedNotificationType, setOptionalSelectedNotificationType] = useState(null);
  const [selectedNotificationType, setSelectedNotificationType] = useState(null);
  const [saveData, setSaveData] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);  
  const { handleReloadComponents , showSnackBar} = useAppContext();
  const [isTitleDefault, setIsTitleDefault] = useState(true); 

  

//New Notification Title

const handleClearTitle = () => {
  setNotificationTitle('');
  setIsTitleDefault(true);
  setPrintedFieldTitle('Create New Notification Type');
};


const handlePrintFieldTitle = () => { 
  setPrintedFieldTitle(notificationTitle); 
  setIsTitleDefault(false);
};






  //Save the field on Enter key press 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Logic to save field on Enter key press
    }
  };

  // Setters for the fields
  const handleSetTitle = (newValue) => {
    setNotificationTitle(newValue);
  };

  const handleSetRequiredFieldName = (newValue) => {
    setRequiredFieldName(newValue);
  };

  const handleCleaRequiredFieldName = () => {
    setRequiredFieldName('');
    setSelectedIcon(null);
  };  

  const handleSelectedIcon = (icon) => {
    setSelectedIcon(icon); // Set selected icon state
  };



  const handleSaveRequiredFieldData = () => {
    setRequiredFieldDataSaved(true);
  };

  const handleSelectFieldType = (newValue) => {
    setSelectedNotificationType(newValue);
  };

  const handleClearRequiredFieldData = () => {  
    setRequiredFieldName('');
    setSelectedIcon(null);
    setSelectedNotificationType(null);
    setRequiredFieldDataSaved(false);
  };


//Optional Field Data





const handleSetOptionalFieldName = (newValue) => {
  setOptionalFieldName(newValue);
};

const handleSelectedOptionalIcon = (icon) => {
  setOptionalIcon(icon); // Set selected icon state
};


const handleSelectOptionalFieldType = (newValue) => {
  setOptionalSelectedNotificationType(newValue);
};

const handleClearOptionalFieldName = () => {
  setOptionalFieldName('');
  setOptionalIcon(null);
};  

const handleClearOptionalFieldData = () => {  
  setOptionalFieldName('');
  setOptionalIcon(null);
  setOptionalSelectedNotificationType(null);
  setOptionalFieldDataSaved(false);
};

const handleSaveOptionalFieldData = () => {
  setOptionalFieldDataSaved(true);
};







const handleSaveNewFieldData = async (titleColor) => {
  await handleSubmit(
    notificationTitle,
    requiredFieldName,
    selectedIcon,
    selectedNotificationType,
    optionalFieldName,
    optionalIcon,
    optionalSelectedNotificationType,
    user,
    apiUrl,
    getAccessTokenSilently,
    handleReloadComponents,
    showSnackBar,
    setIsLoading,
    titleColor
  );
};


  // Return the state and handler functions
  return {
    handleKeyDown,
    notificationTitle,
    requiredFieldName,
    optionalFieldName,
    handleCleaRequiredFieldName,
    handleSetTitle,
    handleSetRequiredFieldName,
    handleSetOptionalFieldName,
    handleSelectedIcon,
    selectedIcon,
    setSelectedIcon,
    handleSelectedOptionalIcon,
    handleSaveRequiredFieldData,
    requiredFieldDataSaved,
    handleSelectFieldType,
    selectedNotificationType,
    handleSaveNewFieldData,
    handleClearRequiredFieldData,
    handleSelectOptionalFieldType,
    handleClearOptionalFieldName,
    handleClearOptionalFieldData,
    handleSaveOptionalFieldData,
    optionalIcon,
    optionalSelectedNotificationType,
    setOptionalIcon,
    optionalFieldDataSaved,
    setOptionalSelectedNotificationType,
    handleClearTitle,
    handlePrintFieldTitle,
    isTitleDefault,
    printedFieldTitle




  };
};