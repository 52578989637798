export const handleSubmit = async (
  notificationTitle,
  requiredFieldName,
  selectedIcon,
  selectedNotificationType,
  optionalFieldName,
  optionalIcon,
  optionalSelectedNotificationType,
  user,
  apiUrl,
  getAccessTokenSilently,
  handleReloadComponents,
  showSnackBar,
  setIsLoading,
  titleColor
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const accessToken = await getAccessTokenSilently();

// Initialize the Fields object
const requestBody = {
  [notificationTitle]: {
    Fields: {
      // Required field
      [requiredFieldName]: {
        icon: selectedIcon,
        value: "",
        fieldType: selectedNotificationType,
        valueType: "INT",
        isRequired: true,
        messagePrefix: "",
        notificationType: requiredFieldName,
      },
      // Optional field - only add if optionalFieldName is defined
      ...(optionalFieldName && {
        [optionalFieldName]: {
          tag: "",  // Ensure to include the 'tag' if needed
          icon: optionalIcon,
          value: "",
          fieldType: optionalSelectedNotificationType,
          valueType: "INT",
          isRequired: false,
          messagePrefix: "",  // Adjust this as needed
          notificationType: optionalSelectedNotificationType,
        },
      }),
    },
    Configs: {
      Tag: notificationTitle,
      Color: titleColor,
      is_default: "false",
      notificationId: "",
      "Context Message": "",
    },
  },
};


    const response = await fetch(
      `${apiUrl}/api/user-notifications/add-new-notification/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to save or update data");
    }

    handleReloadComponents();
    showSnackBar("New Notification Added", true);
  } catch (error) {
    console.error("Error saving data:", error);
  } finally {
    setIsLoading(false); // Ensure loading state is reset in both success and failure cases
  }
};