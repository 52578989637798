// useColorPicker.js
import { useState } from 'react';
import { generateColor } from './generateColor'; // Import the function that generates the color

const useColorPicker = () => {
  const [colors, setColors] = useState({
    cardBackgroundColor: '#FFFFFF',
    borderColor: 'rgba(173, 216, 230, 0.7)',
    fieldFontTextColor: '#000000',
    iconBackgroundColor: '#FFFFFF',
    titleColor: '#48768C',
    containerBackgroundColor: '#FFFFFF',
    iconColor: '#2BBB9E',   
    isDefault: true
    
  });

  const handleIconClick = (color) => {
    const newColors = generateColor(color) || {}; // Ensure fallback to avoid undefined
    setColors(newColors); // Update local state
  };



  

  return { colors, handleIconClick };
};

export default useColorPicker;