import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { confirmedNotifications } from './confirmedNotificationButton';  
import { useAccountContext } from 'src/context/AccountContext';

const useFetchOnboardingRecord = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [onboardingData, setOnboardingData] = useState(null);
  const [onboardingProgress, setOnboardingProgress] = useState(0); // Overall progress
  const [requiredItemsProgress, setRequiredItemsProgress] = useState(0); // Required items progress
  const [optionalItemsProgress, setOptionalItemsProgress] = useState(0); // Optional items progress
  const [totalOptionalItems, setTotalOptionalItems] = useState(0); // Optional items count
  const { accountData } = useAccountContext();
  const [completedOnboarding, setCompletedOnboarding] = useState(false);
  const [locations, setLocations] = useState([]);
  const [totalLocations, setTotalLocations] = useState(0);
  const [loadingLocations, setLoadingLocations] = useState(false);
  

  const apiUrl = process.env.REACT_APP_API_URL;







  const fetchLocations = async () => {
    try {
      setLoadingLocations(true);
      const accessToken = await getAccessTokenSilently();

      if (isAuthenticated && user && user.sub) {
        const response = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();
        setLocations(data.rows);
        setTotalLocations(data.rows.length); // Set total number of locations
      } else {
        console.warn("User is not authenticated or user.sub is missing");
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setError(error);
    } finally {
      setLoadingLocations(false);
    }
  };

  // Use useEffect to trigger fetchLocations when user is available
  useEffect(() => {
    // Only call fetchLocations if user and sub are fully available
    if (isAuthenticated && user?.sub) {
      fetchLocations(); // Fetch locations once the user is available
    }
  }, [isAuthenticated, user?.sub]); // Add user?.sub to avoid calling too early



  return {
    loading,
    error,
    onboardingData,
    onboardingProgress,
    requiredItemsProgress,
    optionalItemsProgress,
    totalOptionalItems,
    completedOnboarding,
    fetchLocations,
    locations,
    totalLocations,
  };
};

export default useFetchOnboardingRecord;