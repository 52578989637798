import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from 'src/context/AppContext';

const useCancelSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelError, setCancelError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const {showSnackBar} = useAppContext(); 

  const cancelSubscription = async (customerId, subscriptionId, onSubscriptionUpdate) => {
    try {
      setIsCancelling(true);
      setCancelError(null);
      setCancelSuccess(null);

      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/subscriptions/manage/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Passing the Auth0 token for secure communication
        },
        body: JSON.stringify({
          customer_id: customerId,
          subscription_id: subscriptionId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      const data = await response.json();
      setCancelSuccess(data.message);
      showSnackBar(data.message, 'Subscription Canceled', 'success'); 
      onSubscriptionUpdate();  // Trigger refetch in parent component

    } catch (error) {
      showSnackBar('Error canceling subscription', 'error');
      console.error('Error canceling subscription:', error);
      setCancelError('Error canceling subscription');
    } finally {
      setIsCancelling(false);
    }
  };

  return {
    isCancelling,
    cancelError,
    cancelSuccess,
    cancelSubscription,
  };
};

export default useCancelSubscription;