import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Stack, IconButton, DialogActions, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import hoursIcon from '../../public/assets/icons/icons/hours.png';
import { useUserSelectedNotifications } from "../../context/UserNotificationsContext";
import dayjs from 'dayjs';

// Exporting the checkIfOpen function
export const checkIfOpen = (businessHours) => {
  const currentDay = dayjs().format('dddd'); // Get current weekday, e.g., 'Monday'
  const currentTime = dayjs(); // Get current time

  const todayHours = businessHours[currentDay];

  if (todayHours.is_closed || !todayHours.start_time || !todayHours.end_time) {

    return false;
  }

  const startTime = dayjs(todayHours.start_time, 'h:mm A');
  const endTime = dayjs(todayHours.end_time, 'h:mm A');

  return currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
};


export const checkIfScheduledOpen = (businessHours, selectedDateTime) => {
    const selectedDay = dayjs(selectedDateTime).format('dddd'); // Get selected weekday, e.g., 'Monday'
    const selectedTime = dayjs(selectedDateTime); // Get selected time
  
    const selectedDayHours = businessHours[selectedDay];
  
    if (selectedDayHours.is_closed || !selectedDayHours.start_time || !selectedDayHours.end_time) {
      return false;
    }
  
    const startTime = dayjs(selectedDayHours.start_time, 'h:mm A');
    const endTime = dayjs(selectedDayHours.end_time, 'h:mm A');
  
    return selectedTime.isAfter(startTime) && selectedTime.isBefore(endTime);
  };
  




export const sendWhenOpen = (businessHours, setSelectedDateTime) => {
    const currentTime = dayjs(); // Get current time
    let nextAvailableTime = null;
  
    // Iterate through business hours starting from today
    for (let i = 0; i < 7; i++) {
      const dayToCheck = currentTime.add(i, 'day').format('dddd');
      const dayHours = businessHours[dayToCheck];
  
      if (dayHours && !dayHours.is_closed && dayHours.start_time && dayHours.end_time) {
        const startTime = dayjs(`${currentTime.add(i, 'day').format('MM/DD/YYYY')} ${dayHours.start_time}`, 'MM/DD/YYYY h:mm A');
        
        if (i === 0 && currentTime.isBefore(startTime)) {
          // If today and the current time is before the start time
          nextAvailableTime = startTime;
          break;
        } else if (i > 0) {
          // For future days, get the start time directly
          nextAvailableTime = startTime;
          break;
        }
      }
    }
  
    if (nextAvailableTime) {
      setSelectedDateTime(nextAvailableTime);
      // Note: Removed the log for selectedDateTime here
      return nextAvailableTime.format('dddd, MM/DD/YYYY h:mm A');
    } else {
      return 'No available time to send';
    }
  };


// AfterHoursDialog component
export default function AfterHoursDialog({ openDialog, setOpenDialog, loading,handleOpenScheduleDialog, selectedDateTime, setSelectedDateTime, setLoading }) {
  const { businessHours } = useUserSelectedNotifications();
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const formattedDate = nextAvailableTime ? nextAvailableTime.format('dddd, MM/DD/YYYY h:mm A') : '';


  useEffect(() => {
    checkIfOpen(businessHours); // Check if open on component mount and when business hours change
  }, [businessHours]);



  // Calculate next available time
  useEffect(() => {
    const calculateNextAvailableTime = () => {
      const currentTime = dayjs(); // Get current time
      let nextAvailableTime = null;

      for (let i = 0; i < 7; i++) {
        const dayToCheck = currentTime.add(i, 'day').format('dddd');
        const dayHours = businessHours[dayToCheck];

        if (dayHours && !dayHours.is_closed && dayHours.start_time && dayHours.end_time) {
          const startTime = dayjs(`${currentTime.add(i, 'day').format('MM/DD/YYYY')} ${dayHours.start_time}`, 'MM/DD/YYYY h:mm A');
          
          if (i === 0 && currentTime.isBefore(startTime)) {
            nextAvailableTime = startTime;
            break;
          } else if (i > 0) {
            nextAvailableTime = startTime;
            break;
          }
        }
      }

      if (nextAvailableTime) {
        setNextAvailableTime(nextAvailableTime);
      } else {
        setNextAvailableTime(null);
      }
    };

    calculateNextAvailableTime();
  }, [businessHours]);

  
  const handleButtonClick = () => {
    setLoading(true);
    const result = sendWhenOpen(businessHours, setSelectedDateTime);
    setOpenDialog(false);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedDateTime) {
    }
  }, [selectedDateTime]);



  const handleScheduleTime = () => {
    setOpenDialog(false);
    handleOpenScheduleDialog(); // Added parentheses to invoke the function
  };


  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle sx={{ padding: 2, position: 'relative' }}>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 2,
            top: 5,
            color: "#48768C",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            padding: "3px",
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
  
        <Stack direction="column" spacing={2}>
          <img
            src={hoursIcon}
            alt="bell"
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#f0f4f8",
              borderRadius: "10px",
              padding: "4px",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
            }}
          />
          <Typography
            sx={{
              color: "#48768C",
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: 1.2,
            }}
          >
           Send Notification After Hours 
          </Typography>
        </Stack>
      </DialogTitle>
  
      <DialogContent sx={{ padding: 2, mt:1 }}>
                <Typography
         sx={{
           color: "#48768C",
           fontWeight: 600,
           lineHeight: '-2%',
           letterSpacing: '-2%',
           fontSize: '17px',
           mt:.5,
           mb: 2,

         }}
       >
         You can only notify your customers during normal business hours. 
       </Typography>
        <Typography
          sx={{
            color: "#48768C",
            fontWeight: 400,
            fontSize: '16.5px',
            mb: 1,
          }}
        >
          We can schedule your message to automatically send the next time you are open.
        </Typography>
        <Typography
          sx={{
            color: "#48768C",
            fontWeight: 400,
            fontSize: '16.75px',

          }}
        >
          Your notification will automatically be sent on: <b> {formattedDate} </b>.
        </Typography>
      </DialogContent>
  
      <DialogActions>
        <Box sx={{ display: 'flex', gap: 3, padding: 1 }}>
          <Button
            variant="outlined"
            onClick={handleScheduleTime}
            sx={{
              color: '#48768C',
              borderColor: '#48768C',
              borderRadius: '8px',
              padding: '6px 12px',
              fontSize: '16.5px',
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#f0f4f8",
                borderColor: "#3B6B7D",
              },
            }}
          >
            {loading ? "Scheduling..." : "Schedule Custom Time To Send"}
          </Button>
  
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              backgroundColor: '#48768C',
              color: '#FFFFFF',
              borderRadius: '8px',
              padding: '6px 12px',
              fontSize: '16.5px',
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#3B6B7D",
              },
            }}
          >
            {loading ? "Scheduling..." : "Schedule Notification"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}