import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  TextField,
  Box,
  TableFooter,
  Divider,
  Paper,
  Avatar,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress
} from "@mui/material";
import { styled, } from "@mui/system";
import AddUserModal from "../../../modal/AddUserModal.js";
import AddLocationModal from "../../../modal/AddLocationModal.js";
import { useAuth0 } from "@auth0/auth0-react";
import EditUsersModal from "../../../modal/EditUsersModal.js";
import EditLocationModal from "../../../modal/EditLocationModal.js";
import ChangePinModal from "../../../modal/ChangePinModal.js";
import DeleteConfirmationDialog from "../../../modal/DeleteConfirmationDialog.js";
import DeleteLocationDialog from "../../../modal/DeleteLocationDialog.js";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAccountContext } from "src/context/AccountContext";
import addIcon from "./../../../../public/assets/icons/icons/add.png";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '../../../../public/assets/icons/icons/help-center/edit.png';
import DeleteIcon from '../../../../public/assets/icons/icons/delete.png';
import DeleteDisabledIcon from '../../../../public/assets/icons/icons/deleteDisabled.png';
import {useAppContext} from '../../../../context/AppContext.js';
import { useAuth } from "../../../../context/AuthContext.js";
import { first, set } from "lodash";
import ViewUsersModal from "../../../modal/ViewUsersModal.js";  
import ViewLocationModal from "../../../modal/ViewLocationModal.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { sub } from "date-fns";
import MultiLocationUpgrade from "../../../Account-Upgrades/components/MultiLocationUpgrade/multiLocationUpgrade.js";
import MultiUserUpgrade from "../../../Account-Upgrades/components/MultiUserUpgrade/multiUserUpgrade.js";
import DowngradeDialog from "./downgradeDialog.js";
import ReviewDialog from 'src/components/modal/ReviewDialog/ReviewDialog';
import useAddNewUser from 'src/components/payments/hooks/useAddNewUser.js'
import useDeleteUser from 'src/components/payments/hooks/useDeleteUser.js'
import useDeleteLocation from 'src/components/payments/hooks/useDeleteLocation.js'  











const AccountMgmtHeader = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.2rem",
  marginBottom: "1rem",
  textAlign: "left",
});

const InfoCard = styled(Card)({
  padding: "1rem",
  width: "100%",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  justifyContent: "space-between",
  backgroundColor: 'rgba(191, 218, 231, 0.1)',
  border: '1px solid rgba(173, 216, 230, 0.7)',
});

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

function AccountManagement({ fetchUsers, loadingUsers, adminCount, users, loadingLocations, locations, setLocations, fetchLocations, setLoadingLocations, totalUsers, setTotalUsers, totalLocations, setTotalLocations }) {
  const { username, subUserData, setSubUserData } = useAuth();
  const { accountData, handleCloseSuccessDialog } = useAccountContext(); // Use the accountData from the context
  const { user, getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [isLocationDeleteConfirmationOpen,setIsLocationDeleteConfirmationOpen,] = useState(false);
  const [deleteSelfDialogOpen, setDeleteSelfDialogOpen] = useState(false);
  const [selectedUserForView, setSelectedUserForView] = useState(null);
  const [multiUserUpgradeDialogOpen, setMultiUserUpgradeDialogOpen] = useState(false); 
  const [isDowngradeDialogOpen, setIsDowngradeDialogOpen] = useState(false);
  const [downgradeDialogTitle, setDowngradeDialogTitle] = useState(''); 
  const [dialogSubText, setDialogSubText] = useState(''); 
  const [downgradeError, setDowngradeError] = useState(''); 
  const {deleteUser,        deleteUserLoading,
    deleteUserError,} = useDeleteUser();
  const [isLoading, setIsLoading] = useState(false);
  const {handleReloadComponents, showSnackBar} = useAppContext(); 
  const {deleteLocation, deleteLocationLoading, deleteLocationError} = useDeleteLocation();
  const [isDowngradingToMultiUser, setIsDowngradingToMultiUser] = useState(false);










  const handleDowngradeDialogOpen = () => { 
    setIsDowngradeDialogOpen(true);
  };



  const handleDowngradeDialogClose = () => {
    setIsDowngradeDialogOpen(false);
    setDowngradeDialogTitle('');
    setIsDowngradingLocation(false);  
    setDowngradeError(false);
    setDialogSubText(''); 
  };




 
  const handleDeleteConfirmationOpen = (userId, username, first_name, last_name, title) => {
    setItemToDelete({ user_id: userId, username: username, first_name: first_name, last_name: last_name, title: title });  
  
    // Check if totalUsers equals totalLocations
    if (totalUsers === totalLocations) {
      setDowngradeDialogTitle('Your total locations cannot exceed the total number of users.');
      setDialogSubText('Multi-Location Accounts Must Have at least One User Per Location.'); 
      setDowngradeError(true);
      setIsDowngradeDialogOpen(true);
      return; // Stop further execution
    }
  
    // If totalUsers >= 3, open the delete confirmation dialog
    if (totalUsers >= 3) {
      setIsDeleteConfirmationOpen(true);
    } 
    else {
      // If fewer than 3 users, show downgrade dialog with a different message
      setDowngradeDialogTitle('Deleting this user will downgrade your account to a single-user account.');
      setDialogSubText('Are you sure you want to continue?'); 
      setIsDowngradingToMultiUser(true);

      setIsDowngradeDialogOpen(true);
    }
  };

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
    setItemToDelete(null);
  };

  const [locationUpgradeDialogOpen, setLocationUpgradeDialogOpen] = useState(false);

  const handleLocationUpgradeDialogOpen = () => {
    setLocationUpgradeDialogOpen(true); // Corrected function call
  };
  
  const handleLocationUpgradeDialogClose = () => {
    setLocationUpgradeDialogOpen(false); // Corrected function call
  };

  const [userUpgradeDialogOpen, setUserUpgradeDialogOpen] = useState(false);

  const handleUserUpgradeDialogOpen = () => {
    setUserUpgradeDialogOpen(true); // Corrected function call
  };
  
  const handleUserUpgradeDialogClose = () => {
    setUserUpgradeDialogOpen(false); // Corrected function call
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    assigned_location: "",
    is_admin:"",
    title:"", 
    user_id: "",

  });

  




  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false);
  const [viewData, setViewData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    assigned_location: "",
    is_admin:"",
    title:"", 

  });

  const handleEditOpen = (user) => {
    if (!user || !user.user_id) {
      console.error("Invalid user data for editing");
      return;
    }

    setSelectedUserForEdit(user);
    setEditData({
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      assigned_location: user.assigned_location,
      is_admin:user.is_admin,
      title: user.title, // Use the correct property name
      user_id: user.user_id,


    });

    setIsEditModalOpen(true);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedUserForEdit(null);
    setEditData({
      first_name: "",
      last_name: "",
      username: "",
      assigned_location: "",
      is_admin:"",
      title:"", 

    });
  };


  const handleViewUserOpen = (user) => {
    if (!user || !user.user_id) {
      console.error("Invalid user data for editing");
      return;
    }

    setSelectedUserForView(user);
    setViewData({
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      assigned_location: user.assigned_location,
      is_admin:user.is_admin,
      title: user.title, // Use the correct property name
      user_id: user.user_id,


    });

    setIsViewUserModalOpen(true);
  };

  const handleViewClose = () => {
    setIsViewUserModalOpen(false);
    setSelectedUserForView(null);
    setViewData({
      first_name: "",
      last_name: "",
      username: "",
      assigned_location: "",
      is_admin:"",
      title:"", 

    });
  };

  const handleEditSave = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      const response = await fetch(
        `${apiUrl}/api/users/${selectedUserForEdit.user_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            firstName: editData.first_name,
            lastName: editData.last_name,
            userName: editData.username,
            assignedLocation: editData.assigned_location,
            isAdmin: editData.is_admin,
            title: editData.title,
            // Add other properties as needed
          }),
        },
      );
  
      const responseData = await response.json();
  
      if (response.ok) {
          fetchUsers(); // Refresh the user list
          handleEditClose(); // Close the edit modal
    
          // Fetch updated user data
          const updatedUserDataResponse = await fetch(
            `${apiUrl}/api/users/profile/${user.sub}/${username}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Include access token in headers
                },
            }
        );
          const updatedUserData = await updatedUserDataResponse.json();
    
          // Update the user data in the context directly
          setSubUserData(updatedUserData);
        } else {
          console.error('Failed to update location');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  

  const [isLastUserConfirmationOpen, setIsLastUserConfirmationOpen] = useState(false);


  const handleDeleteConfirmation = async () => {
    try {
      setIsLoading(true);

      const { user_id, username } = itemToDelete; // Assuming itemToDelete is defined elsewhere
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      // Fetch the list of users
      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const usersData = Array.isArray(data.users) ? data.users : [];

      
      setTotalUsers(usersData.length);

      // Check if there are exactly 2 users left
      if (usersData.length === 2) {
        setIsLoading(false);

        setIsDowngradeDialogOpen(true);

        return;
      }

      // Check if there is only one user left (optional: separate logic)
      if (usersData.length <= 1) {
        setIsLoading(false);

        setIsLastUserConfirmationOpen(true);

        return;
      }



      // Proceed with deletion if there is more than one user
      const deleteUserResponse = await fetch(`${apiUrl}/api/users/${user_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (deleteUserResponse.ok) {
        await deleteUser(data); // Assuming `data` is passed to `addNewUser` if needed
        showSnackBar('User deleted successfully', 'success');
        setIsLoading(false);

        fetchUsers(); // Refresh user list after deletion
        setIsDeleteConfirmationOpen(false);

      } else {
        if (deleteUserResponse.status === 500) {
          showSnackBar('Failed to delete user. Please try again later.', 'error');
          console.error('Failed to delete user:', deleteUserResponse.statusText);
          setIsLoading(false);

          setIsErrorDialogOpen(true);
        } else {
          setIsLoading(false);
          showSnackBar('Failed to delete user. Please try again later.', 'error');


          console.error('Failed to delete user:', deleteUserResponse.statusText);
        }
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  
  

  const handleAddUser = () => {
    setIsAddUserModalOpen(true);
  };

  const handleAddUserModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        if (user && user.sub) {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();
  
          const response = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
            headers: {
              "Authorization": `Bearer ${accessToken}`
            }
          });
          const data = await response.json();
  
          const locationsData = Array.isArray(data.rows) ? data.rows : [];
          setLocations(data.rows);
          setLoadingLocations(false);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
  
    fetchLocations();
  }, [getAccessTokenSilently, user]);
  

  const [selectedLocationForEdit, setSelectedLocationForEdit] = useState(null);
  const [error, setError] = useState(null);



  const [isViewLocationModalOpen, setIsViewLocationModalOpen] = useState(false);
  const [viewLocationData, setViewLocationData] = useState({
    name: "",
    email: "",
    phone: "",
    location_id: "",
  });

  const handleViewLocationOpen = (location) => {
    setSelectedLocationForEdit(location);
    setEditLocationData({
      name: location.name,
      email: location.email,
      phone: location.phone,
      location_id: location.location_id,

    });
    setIsViewLocationModalOpen(true);
  };

  const handleViewLocationClose = () => {
    setIsViewLocationModalOpen(false);
    setViewLocationData({
      name: "",
      email: "",
      phone: "",
    });
  };





  

  const handleEditLocationSave = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      const response = await fetch(
        `${apiUrl}/api/locations/${selectedLocationForEdit.location_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
          body: JSON.stringify(editLocationData),
        },
      );
  
      if (response.ok) {
        // Handle success, e.g., refetch locations or update state
        setIsEditLocationModalOpen(false);
        fetchLocations(); // Fetch updated locations
      } else {
        console.error("Failed to update location");
      }
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };
  

  const handleEditLocationOpen = (location) => {
    setSelectedLocationForEdit(location);
    setEditLocationData({
      name: location.name,
      email: location.email,
      phone: location.phone,
    });
    setIsEditLocationModalOpen(true);
  };


    const [isEditLocationModalOpen, setIsEditLocationModalOpen] = useState(false);
  const [editLocationData, setEditLocationData] = useState({
    name: "",
    email: "",
    phone: "",
    location_id: "",  
  });

  const handleEditLocationClose = () => {
    setIsEditLocationModalOpen(false);
    setSelectedLocationForEdit(null);
    setEditLocationData({
      name: "",
      email: "",
      phone: "",
    });
  };

const [isDowngradingLocation, setIsDowngradingLocation] = useState(false);  

const handleDeleteLocation = (location) => {
  if (!location) {
    setIsLocationDeleteConfirmationOpen(false);
    console.error("Invalid location data for deletion");
    return; // Stop further execution if location is invalid
  }

  // Check if totalLocations is 2 first


  // Proceed with location delete confirmation for other cases
  setLocationToDelete(location);
  setIsLocationDeleteConfirmationOpen(true);
};


  const handleDeleteLocationConfirmation = async (locationToDelete) => {

    try {
      

      if (!locationToDelete || !locationToDelete.location_id) {
        console.error("Invalid location data for deletion");
        return;
      }
  
      const { location_id, name } = locationToDelete;
      setLocationToDelete(locationToDelete);
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
  
      // Fetch users assigned to any location
      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      const userData = await response.json();
  
  
      // Check if any user is assigned to the location to be deleted
      const usersInLocation = userData.users.filter(user => user.active_location === location_id);
  
  
      if (usersInLocation.length > 0) {
        // Display error message if there are users assigned to this location
        setErrorMessage("The location you are trying to delete still has users assigned to it. You need to reassign those users to another location before deleting this one.");
        setIsErrorDialogOpen(true);
        setIsLoading(false);

        return;
      }



      if (totalLocations === 2) {
        setIsLocationDeleteConfirmationOpen(false);
        setDowngradeDialogTitle('Deleting this location will downgrade your account to a single-location account.');
        setDialogSubText('Are you sure you want to continue?'); 
        setDowngradeError(false);
        setIsDowngradingLocation(true);
        setIsDowngradeDialogOpen(true);
        setIsLoading(false);

        return; // Stop further execution to prevent opening the delete confirmation dialog
      }

  
      // No users assigned, proceed with deletion
      const deleteResponse = await fetch(`${apiUrl}/api/locations/${location_id}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
  
      if (deleteResponse.ok) {
        await deleteLocation(); // Assuming `data` is passed to `addNewUser` if needed
        showSnackBar('Location deleted successfully', 'success');
        setIsLoading(false);
        // Location successfully deleted
        fetchLocations();
        setIsLocationDeleteConfirmationOpen(false);
        setLocationToDelete(null);
      } else {
        // Display error message for server errors
        console.error("Failed to delete location:", deleteResponse.statusText);
        setErrorMessage("Failed to delete location. Please try again later.");
        setIsErrorDialogOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Error deleting location:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setIsErrorDialogOpen(true);
      setIsLoading(false);
    }
  };
  
  
  

  const [errorMessage, setErrorMessage] = useState('');
const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  

  const handleAddLocation = () => {
    setIsAddLocationModalOpen(true);
  };

  const handleAddLocationModalClose = () => {
    setIsAddLocationModalOpen(false);
  };

  const LastTableRow = styled(TableRow)({
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  });

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };
  

const [multiLocationUpgradeDialogOpen, setMultiLocationUpgradeDialogOpen] = useState(false);   
const [isDialogClosing, setIsDialogClosing] = useState(false);




const handleMultiLocationUpgradeDialogOpen = () => {
  setMultiLocationUpgradeDialogOpen(true);
};

const handleMultiLocationUpgradeDialogClose = () => {
  setMultiLocationUpgradeDialogOpen(false);
};



const handleMultiUserUpgradeDialogOpen = () => {
  setMultiUserUpgradeDialogOpen(true);
};

const handleMultiUserUpgradeDialogClose = () => {
  setMultiUserUpgradeDialogOpen(false);
};






  return (
    <Box sx={{ height: 'auto',  width:'100%' }}>
      <Box>
      {accountData.single_user && ( // Hide the InfoCard if single_user is false
      

      <InfoCard sx={{ mb: 6, mt:7, width:'100%'}}>
        <Typography
          variant="h6"
          style={{ marginBottom:'5px', color: "#48768C", fontWeight: 600, fontSize:16.5, marginBottom:2}}
          >
          Your Active Employees
        </Typography>
        <TableContainer style={{ borderCollapse: "collapse" }}>
          <Table 
          size="small"
          sx={{ borderCollapse: "collapse" }}>
            <TableHead>
              <TableRow
              sx={{color: "#7C8795", fontWeight: 600, fontSize:13}}>
              <TableCell
              sx={{backgroundColor:'transparent', borderBottom:'none'}}
              >
              Your account was created as a single user account. Upgrade your plan to add more users.
              </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="contained"
  onClick={handleMultiUserUpgradeDialogOpen}
  sx={{
    fontSize: 13.5,
    justifyContent: "center",
    color: '#48768C',
    mt: 2,
    mr: 0.5,
    p: '7px',
    backgroundColor: '#FFFFFF',
    whiteSpace: "nowrap",
    border: ".5px solid #B0BEC5",

    borderRadius: "10px",

    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#DBEAF0",
    },
    "&:active": {
      backgroundColor: "#C0D0D6", // Adjust active state color if needed
    },
  }}
>
            Upgrade Account
          </Button>
        </Box>
      </InfoCard>
      )}

{!accountData.single_user && ( // Add this condition to hide the InfoCard if single_user is true


<InfoCard sx={{ mt: 4, width:'100%', }}>
  {/* Top row containing "Manage your users" */}
  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
    <Typography variant="body1" style={{ color: "#48768C", fontWeight: 600, }}>
    Your Active Employees

    </Typography>
    {subUserData.is_admin && ( // Show the Add User button only if the user is an admin}
    <Button
      variant="contained"
      onClick={handleAddUser}
      sx={{
        color:'#FFFFFF',
        minWidth: 45,
        height: "30px",
        borderRadius: "10px",
        p: '1px', // Adjust padding as needed
        backgroundColor: "#F2F9FF", // Set background color to transparent
        border: '1px solid rgba(173, 216, 230, 0.7)',

        '&:hover': {
          backgroundColor: '#A5CADB', // Adjust the background color for contrast
          color: '#F0F0F0', // Keep text color consistent
        },
      }}
    >
      <img
        src={addIcon}
        alt="add"
        style={{
          width: "auto",
          height: "26px",
        }}
      />
    </Button>
    )}
  </Box>
       

<Box sx={{ display: "flex", flexDirection: "column" }}>
<Paper elevation={3} sx={{backgroundColor: 'rgba(191, 218, 231, 0.1)',borderRadius:'10px'}}>

  <TableContainer sx={{padding:1.5, border: '1px solid rgba(173, 216, 230, 0.7)',borderRadius:'10px', minHeight:'10vh'}}>
    <Table size="small">
      <TableBody>
        {Array.isArray(users) && users.length > 0 ? (
          users.map((user, index) => (
            <TableRow
              key={user.user_id}
              sx={{
                borderRadius: "40px",
                cursor: "pointer",
                justifyContent:'space-between',
                borderBottom:'none',
                "&:hover": {
                  backgroundColor: "#EAF6FF",
                },
              }}
            >
              <TableCell
                onClick={() => handleViewUserOpen(user)}
                sx={{
                  padding: "10px",                justifyContent:'space-between',                borderBottom:'none',


                  "&:hover": { backgroundColor: "#EAF6FF" },
                }}
              >
                <Avatar
                  sx={{
                    color: "#48768C",
                    fontSize: "15px",
                    width: 40,
                    height: 40,
                    background: 'radial-gradient(circle, #EEF6F9, #FFFFFF)',
									border: '2px solid rgba(173, 216, 230, 0.9)', 
                    borderRadius: "20px",
                    padding: "4px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.01)",
                    marginRight: 1,
                    fontWeight: 600,  

                  }}
                >
                  {getInitials(user.first_name, user.last_name)}
                </Avatar>
              </TableCell>
              <TableCell
                onClick={() => handleViewUserOpen(user)}
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#48768C",
                  padding: "10px",
                  justifyContent:'space-between',
                  paddingRight:5,
                  borderBottom:'none',


                }}
              >
                {user.first_name} {user.last_name}
              </TableCell>
              <TableCell
                onClick={() => handleViewUserOpen(user)}
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#48768C",
                  padding: "10px",
                  textAlign: "left",
                  justifyContent:'space-between',
                  paddingRight:5,
                  borderBottom:'none',


                  

                }}
              >
                {user.username}
              </TableCell>
              <TableCell
                onClick={() => handleViewUserOpen(user)}
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#48768C",
                  padding: "10px",
                  textAlign: "left",
                  justifyContent:'space-between',
                  paddingRight:5,
                  borderBottom:'none',



                }}
              >
                {user.title}
              </TableCell>
              {!accountData.single_location && (
                <TableCell
                  onClick={() => handleViewUserOpen(user)}
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#48768C",
                    padding: "10px",
                    textAlign: "left",
                    justifyContent:'space-between',
                    paddingRight:5,
                    borderBottom:'none',



                  }}
                >
                  {user.location_name}
                </TableCell>
              )}
              <TableCell
                onClick={() => handleViewUserOpen(user)}
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#48768C",
                  padding: "10px",
                  textAlign: "left",
                  pl: "20px",
                  justifyContent:'space-between',
                  paddingRight:5,
                  borderBottom:'none',



                }}
              >
                {user.is_admin ? "Administrator" : "Employee"}
              </TableCell>
              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100px",
                  padding: "10px",
                  textAlign: "left",
                  borderBottom:'none',


                }}
              >
                {subUserData.is_admin && (

                <IconButton onClick={() => handleEditOpen(user)} sx={{ ml: 2 }}>
                  <img
                    src={EditIcon}
                    alt="detailsIcon"
                    style={{
                      border: "1px solid #B0BEC5",
                      width: "auto",
                      height: "25px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "6px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                    }}
                  />


                </IconButton>
                                )}

                {user.username !== username && subUserData.is_admin && (
                  <IconButton onClick={() => handleDeleteConfirmationOpen(user.user_id, user.username, user.first_name, user.last_name, user.title)} sx={{ ml: 2 }}>
                    <img
                      src={DeleteIcon}
                      alt="detailsIcon"
                      style={{
                        border: "1px solid #B0BEC5",
                        width: "auto",
                        paddingTop: ".5px",
                        height: "25px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "6px",
                        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                      }}
                    />
                  </IconButton>
                )}
                {user.username === username && subUserData.is_admin && (
                  <IconButton                       disabled
                  sx={{ ml: 2, alignItems:'center' }}>
                    <img
                      src={DeleteIcon}
                      alt="detailsIcon"
                      style={{
                        border: "1px solid #B0BEC5",
                        width: "auto",
                        paddingTop: ".5px",
                        height: "25px",
                        backgroundColor: "#F0F0F0",
                        borderRadius: "6px",
                        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",


                      }}
                    />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7}>
              {loadingUsers || !users ? (
                <Box>
                  <Typography
                    align="center"
                    sx={{
                      background: 'transparent',
                      width: '100%',
                      padding: '20px',
                      color: '#447c92',
                      fontSize: '.8rem',
                      fontWeight: 600,
                    }}
                  >
                    Loading Your Users...
                  </Typography>
                  <LinearProgress
                    color="secondary"
                    sx={{
                      height: 2,
                      '& .MuiLinearProgress-barColorSecondary': {
                        backgroundColor: '#2BBB9E',
                      },
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ padding: "20px" }}>
                  <Typography style={{ color: "#48768C", fontWeight: 600, fontSize: 14 }}>
                    No users found
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  </Paper>
</Box>


          <Dialog open={deleteSelfDialogOpen} onClose={() => setDeleteSelfDialogOpen(false)}PaperProps={{ sx: { borderRadius: "10px",border: '1px solid rgba(173, 216, 230, 0.7)', } }}
>
<Box sx={{
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'rgba(191, 218, 231, 0.09)',border: '1px solid rgba(173, 216, 230, 0.7)', color: '#48768C',padding:1}}>
                                           
        
<DialogContent  sx={{color:'#48768C',justifyContent:'center', display:'flex', flexDirection:'column', gap:2}}>
<Typography variant="subtitle1"
sx={{
  fontWeight: 600, letterSpacing:'-2%', lineHeight:'-2%', alignItems:'center', alignContent:'center', display:'flex', alignSelf:'center'}} >
  Hang On a Second....</Typography>
  <DialogContentText>

  <Typography variant="subtitle1"
  color="textSecondary"
   sx={{
fontWeight: 500, letterSpacing:'-2%', lineHeight:'-2%'}} >
        You can't delete yourself from the account!
</Typography>
  </DialogContentText>
</DialogContent>

  </Box>
</Dialog>


          <Dialog 
              PaperProps={{ sx: { borderRadius: "30px", } }}

          open={isLastUserConfirmationOpen} onClose={() => setIsLastUserConfirmationOpen(false)}    >

<Box sx={{
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
padding:1,
backgroundColor: '#FFFFFF',
color: '#48768C',
                                    }}>
                                            <IconButton
        aria-label="close"
        onClick={() => {{setIsLastUserConfirmationOpen(false)} setIsDeleteConfirmationOpen(false);}}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
  <DialogTitle       sx={{color:'#48768C',justifyContent:'center', display:'flex',mt:1, fontSize:14}}>

  <Typography 
  sx={{fontSize:15, fontSize: 17,
fontWeight: 600,}} >
    Hang On a Second....</Typography>
    </DialogTitle>
  <DialogContent>
    <DialogContentText>

    <Typography sx={{fontSize:15, fontSize: 15.5,mb:1,
fontWeight: 500,}} >
          This is the only user in your account. Your account must have at least one user.
</Typography>
    </DialogContentText>
  </DialogContent>
  </Box>
</Dialog>


         
        </InfoCard>
)}

<ViewUsersModal
          isOpen={isViewUserModalOpen}
          onClose={() => setIsViewUserModalOpen(false)}
          viewData={viewData}
          setViewData={setViewData}
          locations={locations}
        />

        <EditUsersModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleEditSave}
          editData={editData}
          setEditData={setEditData}
          locations={locations}
          adminCount={adminCount}

        />


        <DeleteConfirmationDialog
          open={isDeleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
          item={itemToDelete}
          itemName={itemToDelete ? itemToDelete.username : ""}
          handleDeleteConfirmation={handleDeleteConfirmation}
          isLoading={isLoading}

        />
      </Box>
      {!accountData.single_location && (

      <InfoCard sx={{ mb: 3, mt:3 }}>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
    <Typography style={{ color: "#48768C", fontWeight: 600, fontSize: 16 }}>
      Your Active Locations
    </Typography>
    {subUserData.is_admin && (
    <Button
      variant="contained"
      onClick={handleAddLocation}
      sx={{
        color:'#FFFFFF',
        minWidth: 45,
        height: "30px",
        borderRadius: "10px",
        p: '1px', // Adjust padding as needed
        backgroundColor: "#F2F9FF", // Set background color to transparent
        border: '1px solid rgba(173, 216, 230, 0.7)',

        '&:hover': {
          backgroundColor: '#A5CADB', // Adjust the background color for contrast
          color: '#F0F0F0', // Keep text color consistent
        },
      }}
    >
      <img
        src={addIcon}
        alt="add"
        style={{
          width: "auto",
          height: "26px",
        }}
      />
    </Button>
    )}
  </Box>
  <Paper elevation={3} sx={{backgroundColor: 'rgba(191, 218, 231, 0.08)',borderRadius:'10px'}}>


  <TableContainer sx={{padding:1.5, border: '1px solid rgba(173, 216, 230, 0.7)',borderRadius:'10px',     minHeight: locations.length <= 1 ? '5vh' : '13vh', // Conditionally set minHeight based on locations length



  }}>
          <Table 
          size="small"
          sx={{ borderCollapse: "collapse" }}>
           
            <TableBody>
              {Array.isArray(locations) && locations.length > 0 ? (
                locations.map((location) => (
                  <TableRow key={location.location_id}
                  
                  sx={{borderRadius: "40px",                        justifyContent:'space-between',

                  "&:hover": {
                    cursor: "pointer",

                    backgroundColor: "#EAF6FF", 
                  },
                  }} >
                    <TableCell
                                            onClick={() => handleViewLocationOpen(location)}
                      
                      sx={{
                        alignItems: "center",
                        color: "#48768C",
                        borderBottom: "none",
                        fontWeight: 600,
                        textAlign: "left",
                        pr:2

                      }}
                    >
                      {location.name}
                    </TableCell>
                    <TableCell
                                                                onClick={() => handleViewLocationOpen(location)}

                      sx={{
                        color: "#48768C",
                        borderBottom: "none",
                        fontWeight: 500,
                        textAlign: "center",
                        fontSize: 13.5,
                        justifyContent:'space-between',
                        


                      }}
                    >
                      {location.email}
                    </TableCell>
                    <TableCell

                                                                onClick={() => handleViewLocationOpen(location)}

                      sx={{
                        color: "#48768C",
                        borderBottom: "none",
                        fontWeight: 500,
                        textAlign: "center",
                        fontSize: 13.5,
                        justifyContent:'space-between',


                      }}
                    >
                      {formatPhoneNumber(location.phone)}
                    </TableCell>

                    <TableCell
                                                                onClick={() => handleViewLocationOpen(location)}

                      sx={{
                        color: "#48768C",
                        borderBottom: "none",
                        fontWeight: 500,
                        textAlign: "left",
                        fontSize: 13.5,
                        justifyContent:'space-between',


                      }}
                    >
                    </TableCell>
                    
                    <TableCell
                      sx={{
                        color: "#48768C",
                        borderBottom: "none",
                        whiteSpace: "no-wrap",
                        fontWeight: 600,
                        alignItems: "center",
                        width: "90px",

                        


                      }}
                    >
                      <Box
                      sx={{display:'flex', width: "100%",  gap:'10px', pr:2 }}
                      >
                        {subUserData.is_admin && (
                          <>

                      <IconButton
                        onClick={() => handleEditLocationOpen(location)}
                        sx={{  width: "35px",
                        height: "35px",
                        padding: "3px",          mx: 0.5,
                        my: 0.25,}}
                      >
        <img src={EditIcon} alt="detailsIcon" style={{border: "1px solid #B0BEC5", width: "auto", height: "25px", backgroundColor: "#FFFFFF", borderRadius: "6px", padding: "1px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",marginTop:1  }} />
                      </IconButton>

                      <IconButton
                        onClick={() => handleDeleteLocationConfirmation(location)}
                        sx={{  width: "35px",
                        height: "35px",
                        padding: "3px",          mx: 0.5,
                        my: 0.25,}}
                      >
  <img src={DeleteIcon} alt="detailsIcon" style={{border: "1px solid #B0BEC5", width: "auto", height: "25px", backgroundColor: "#FFFFFF", borderRadius: "6px", padding: "1px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",marginTop:1  }} />
                      </IconButton>
                      </>
                      )}  

                      </Box>
        

                    </TableCell>
                  </TableRow>
                 ))
                 ) : (
                   loadingLocations || !locations ? (
                    <TableRow>  
<TableCell colSpan={6} sx={{width:'100%'}} >
                     <Box >
                       <Typography
                         align="center"
                         sx={{
                           background: 'transparent',
                           width: '100%',
                           padding: '20px',
                           color: '#447c92',
                           fontSize: '.8rem',
                           fontWeight: 600,
                         }}
                       >
                         Loading Your Locations...
                       </Typography>
                       <LinearProgress
                         color="secondary"
                         sx={{
                           height: 2,
                           '& .MuiLinearProgress-barColorSecondary': {
                             backgroundColor: '#2BBB9E',
                           },
                         }}
                       />
                     </Box>
                     </TableCell>
                     </TableRow>
                   ) : (
                    <TableRow> 
                      <TableCell colSpan={6} sx={{width:'100%'}} >
                     <Box sx={{ padding: "20px" }}>
    <Typography style={{ color: "#48768C", fontWeight: 600, fontSize: 14 }}>
                        
                        No locations found</Typography>
                     </Box>
                     </TableCell>
           </TableRow>
                     
                   )
                 )}
   
               </TableBody>

            <ViewLocationModal
              isOpen={isViewLocationModalOpen}
              onClose={handleViewLocationClose}
              viewLocationData={editLocationData}
            />
            <EditLocationModal
              isOpen={isEditLocationModalOpen}
              onClose={handleEditLocationClose}
              onSave={handleEditLocationSave}
              editLocationData={editLocationData}
              setEditLocationData={setEditLocationData}
              selectedLocationForEdit={selectedLocationForEdit}
            />
          </Table>
        </TableContainer>
        </Paper>

        <Dialog 
         PaperProps={{ sx: { borderRadius: "10px",            backgroundColor: "#F2F9FF",

         border: '1px solid rgba(173, 216, 230, 0.7)', } }}
         

        open={isErrorDialogOpen} onClose={() => setIsErrorDialogOpen(false)} >
       
<Box sx={{
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
backgroundColor: 'rgba(191, 218, 231, 0.09)',
    border: '1px solid rgba(173, 216, 230, 0.7)', 
color: '#48768C',padding:1
                                }}>
                                       
    

                                       <DialogContent  sx={{color:'#48768C',justifyContent:'center', display:'flex', flexDirection:'column', gap:2}}>
<Typography variant="subtitle1"
sx={{
fontWeight: 600, letterSpacing:'-2%', lineHeight:'-2%', alignItems:'center', alignContent:'center', display:'flex', alignSelf:'center'}} >
Hang On a Second....</Typography>
    <Typography variant="body2"
      sx={{
        
        mb: .25,
        fontWeight: 600,
        textAlign: 'center', // Center the text
      }}
    >
      The location you are trying to delete still has employees assigned to it.
    </Typography>
    <Typography variant="body3"
      sx={{
       
        mb: 1,
        fontWeight: 600,
        textAlign: 'center', // Center the text
        whiteSpace: "noWrap",
      }}
    >
      You need to reassign those employees to another location before deleting this one.
    </Typography>
  </DialogContent>
  </Box>
  </Dialog>
      </InfoCard>
      )}
{accountData.single_location && ( // Show the InfoCard if single_location is true
<InfoCard sx={{ mb: 3,  }}>
<Typography
          variant="h6"
          style={{ marginBottom:'5px', color: "#48768C", fontWeight: 600, fontSize:16.5}}
          >
          Manage Your Locations
        </Typography>
        <TableContainer style={{ borderCollapse: "collapse" }}>
          <Table 
          size="small"
          sx={{ borderCollapse: "collapse" }}>
            <TableHead>
              <TableRow
              sx={{color: "#7C8795", fontWeight: 600, fontSize:13}}>
                <TableCell
                              sx={{backgroundColor:'transparent', borderBottom:'none'}}

                >
              Your account was created as a single location account. Upgrade your account to add more locations.
              </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="contained"
  onClick={handleMultiLocationUpgradeDialogOpen}
  sx={{
    fontSize: 13.5,
    justifyContent: "center",
    color: '#48768C',
    mt: 2,
    mr: 0.5,
    p: '7px',
    backgroundColor: '#FFFFFF',
    whiteSpace: "nowrap",
    border: ".5px solid #B0BEC5",

    borderRadius: "10px",

    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#DBEAF0",
    },
    "&:active": {
      backgroundColor: "#C0D0D6", // Adjust active state color if needed
    },
  }}
>
            Upgrade Account
          </Button>
        </Box>
      </InfoCard>
)}



      {/* AddUserModal component for adding a new user */}
      <AddUserModal
        open={isAddUserModalOpen}
        onClose={handleAddUserModalClose}
        onAddUser={() => {}}  // Pass an empty function here
        locations={locations}
        fetchUsers={fetchUsers}
        
      />

      {/* AddLocationModal component for adding a new location */}
      <AddLocationModal
        open={isAddLocationModalOpen}
        onClose={handleAddLocationModalClose}
        fetchLocations={fetchLocations}
        onAddLocation={(locationData) => {
          handleAddLocationModalClose();
        }}
      />

<MultiLocationUpgrade
      multiLocationUpgradeDialogOpen={multiLocationUpgradeDialogOpen}
      handleMultiLocationUpgradeDialogClose={handleMultiLocationUpgradeDialogClose}
      users={users} 
      singleUser={accountData.single_user} 
      setMultiLocationUpgradeDialogOpen={setMultiLocationUpgradeDialogOpen} 
      isDialogClosing={isDialogClosing}

      
    />

<MultiUserUpgrade
      multiUserUpgradeDialogOpen={multiUserUpgradeDialogOpen}
      handleMultiUserUpgradeDialogClose={handleMultiUserUpgradeDialogClose}
      users={users} 
      isDialogClosing={isDialogClosing}
    />

    <DowngradeDialog
        open={isDowngradeDialogOpen}  // Use the boolean state here
        onClose={handleDowngradeDialogClose}  // Function to close the dialog
        itemToDelete={itemToDelete}  // Pass the item to be deleted
        fetchUsers={fetchUsers}  // Function to fetch users
        downgradeDialogTitle={downgradeDialogTitle}  // Pass the dialog title
        isDowngradingLocation={isDowngradingLocation}  // Pass the boolean state
        locationToDelete={locationToDelete}  // Pass the location to be deleted
        downgradeError={downgradeError}  // Pass the downgrade error  
        dialogSubText={dialogSubText}  // Pass the dialog subtext 
        isDowngradingToMultiUser={isDowngradingToMultiUser}
        
    />

      <DeleteLocationDialog
        open={isLocationDeleteConfirmationOpen}
        onClose={() => {
          setIsLocationDeleteConfirmationOpen(false);
          setLocationToDelete(null);
        }}
        onConfirm={handleDeleteLocationConfirmation}
        itemName={locationToDelete ? locationToDelete.name : ""}
        item={locationToDelete}
        isLoading={isLoading}
      />

    </Box>
    
  );
}

export default AccountManagement;
