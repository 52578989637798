import React, { useState } from 'react';
import { Button, Box, Paper, IconButton, TextField, Typography, Stack, Popover } from '@mui/material';
import IconGrid from '../../../Components/IconGrid.js'
import { useNotificationState } from '../hooks/useNotificationState';
import FieldSelector from "./FieldSelector";






const FieldSelectorPopover = ({fieldOpen,fieldAnchorEl,handleFieldPopoverClose, handleSelectFieldType,closePopover ,onSelectField , selectedNotificationType, setSelectedNotificationType}) => {





return (
<Popover
open={fieldOpen}
anchorEl={fieldAnchorEl}
onClose={handleFieldPopoverClose}
anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
transformOrigin={{ vertical: "top", horizontal: "right" }}
PaperProps={{
  sx: {
    p: 0,
    mt: "auto",
    ml: "auto",
    width: "auto",
    backgroundColor: "#F2F9FF",
    border: '1px solid rgba(173, 216, 230, 0.5)',

    "& .MuiMenuItem-root": { typography: "body2", borderRadius: 0.75 },
  },
}}
>
<Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>



    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 0,
        gap: "5px",
        mb: "5px",
        mt: "5px",
      }}
    >
    

    

    <FieldSelector
          onSelectField={handleSelectFieldType}
          includeCustomMessageBox={false}
          closePopover={handleFieldPopoverClose}
          handleSelectFieldType={handleSelectFieldType}
          selectedValue={selectedNotificationType}
          setSelectedValue={setSelectedNotificationType}



        />
    </Box>

    
  


  
</Box>


</Popover>
);
}


export default FieldSelectorPopover; 
